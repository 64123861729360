// constants
import { AuthActionTypes } from "./constants";

export interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.VALIDATE_CODE
    | AuthActionTypes.LIST_KEYS
    | AuthActionTypes.SAVE_NON_CUSTODIAN_KEY
    | AuthActionTypes.SAVE_TERMS_AND_CONDITIONS;
  payload: {} | string;
}

interface UserData {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
}

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (
  username: string,
  password: string
): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { username, password },
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const signupUser = (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  name: string,
  role: string,
  userType: string
): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { email, password, firstName, lastName, name, role, userType },
});

export const validateCode = (email: string, code: string): AuthActionType => ({
  type: AuthActionTypes.VALIDATE_CODE,
  payload: { email, code },
});

export const saveNonCustodianKeys = (
  publicKey: any,
  address: string
): AuthActionType => ({
  type: AuthActionTypes.SAVE_NON_CUSTODIAN_KEY,
  payload: { publicKey, address },
});

export const listKeys = (): AuthActionType => ({
  type: AuthActionTypes.LIST_KEYS,
  payload: {},
});

export const forgotPassword = (email: string): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { email },
});

export const saveTermsAndConditions = (
  terms: any,
  signature: any
): AuthActionType => ({
  type: AuthActionTypes.SAVE_TERMS_AND_CONDITIONS,
  payload: { terms, signature },
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});
