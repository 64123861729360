import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import Proposal from "./proposals/reducers";
import Stakes from "./stakes/reducers";
import Datalake from "./datalake/reducers";

export default combineReducers({
  Auth,
  Layout,
  Proposal,
  Stakes,
  Datalake,
});
