import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";

import { FormInput } from "../../../components";

import { createDataLake, fetchMyDeployments } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";

import { getDate } from "../../../helpers/helperMethods";
import AddressItem from "../../../components/AddressItem";

import ethereumIcon from "../../../assets/images/brands/ethereum.svg";
import aptosIcon from "../../../assets/images/brands/aptos.svg";
import polygonIcon from "../../../assets/images/brands/polygon.svg";
import solanaIcon from "../../../assets/images/brands/solana.svg";
import bnbIcon from "../../../assets/images/brands/bnb.svg";
import avalancheIcon from "../../../assets/images/brands/avalanche.svg";
import polkadotIcon from "../../../assets/images/brands/polkadot.svg";
import cardanoIcon from "../../../assets/images/brands/cardano.svg";

import {
  APTOS,
  AVALANCHE,
  BNB,
  CARDANO,
  ETHEREUM,
  POLKADOT,
  POLYGON,
  SOLANA,
} from "./constants";
import Loader from "../../../components/Loader";

const CreateDataLake = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const {
    loading,
    error,
    myDeployments,
    getDeploymentLoading,
    getDeploymentSuccess,
    createDataLakeSuccess,
    createLoading,
  } = useSelector((state: RootState) => ({
    loading: state.Datalake.loading,
    error: state.Proposal.error,
    myDeployments: state.Proposal.myDeployments,
    getDeploymentSuccess: state.Proposal.getDeploymentSuccess,
    getDeploymentLoading: state.Proposal.getDeploymentLoading,
    createDataLakeSuccess: state.Datalake.createDataLakeSuccess,
    createLoading: state.Datalake.createLoading,
  }));

  const [name, setName] = useState("");
  const [api, setApi] = useState("");
  const [amazon, setAmazon] = useState("");
  const [selectedDeployments, setSelectedDeployments] = useState(Array);
  const [files, setFiles] = useState(Array);
  const [blockchainList, setBlockchainList] = useState(Array);

  const onValueChange = (event: any, type: string) => {
    if (type === "name") {
      setName(event.target.value);
    } else if (type === "api") {
      setApi(event.target.value);
    } else if (type === "amazon") {
      setAmazon(event.target.value);
    } else if (type === "file") {
      setFiles([event.target.value]);
    }
  };

  const onSelect = (event: any, deployment: any) => {
    let deployments = selectedDeployments;

    if (event.target.checked) {
      deployments.push(deployment.address);
      setSelectedDeployments(deployments);
    } else {
      let index = deployments.findIndex(deployment.address);

      if (index !== -1) {
        let newDeployments = deployments.splice(index, 1);
        setSelectedDeployments(newDeployments);
      }
    }
  };

  const onBlockChainSelect = (event: any, type: any) => {
    let selected = blockchainList;

    if (event.target.checked) {
      selected.push(type);
      setBlockchainList(selected);
    } else {
      let index = selected.findIndex(type);

      if (index !== -1) {
        let newSelected = selected.splice(index, 1);
        setBlockchainList(newSelected);
      }
    }
  };

  const onCreateClick = () => {
    let blockchains = "";
    blockchainList.forEach((item: any, index) => {
      if (index === 0) {
        blockchains = item;
      } else {
        blockchains += "," + item;
      }
    });

    let filesList = "";
    files.forEach((item: any, index) => {
      if (index === 0) {
        filesList = item;
      } else {
        filesList += "," + item;
      }
    });

    let deployments = "";
    selectedDeployments.forEach((item: any, index) => {
      if (index === 0) {
        deployments = item;
      } else {
        deployments += "," + item;
      }
    });

    dispatch(
      createDataLake(name, blockchains, filesList, deployments, amazon, api)
    );
  };

  const onCancel = () => {
    navigate("/data-lakes");
  };

  const onCreateSuccess = () => {
    setTimeout(() => {
      navigate("/data-lakes");
    }, 3000);
  };

  useEffect(() => {
    dispatch(fetchMyDeployments());
  }, [dispatch]);

  return (
    <div>
      {(getDeploymentLoading || loading || createLoading) && <Loader />}

      <h4 className="mt-3 mb-3">Define your Data Lake</h4>

      {createDataLakeSuccess && (
        <Alert variant="success" className="my-2">
          <>
            Data Lake created successfully!
            {onCreateSuccess()}
          </>
        </Alert>
      )}

      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}

      <FormInput
        type="text"
        label="Name"
        name="name"
        className="form-control"
        placeholder="Enter Name"
        containerClass={"mb-3"}
        key="name"
        onChange={(event) => {
          onValueChange(event, "name");
        }}
        value={name}
      />

      <h5>Deployments</h5>
      <div>
        {getDeploymentSuccess && myDeployments?.deployments.length !== 0 && (
          <div className="table-responsive mt-3">
            <table className="table table-sm table-centered mb-0 fs-13">
              <thead className="table-light">
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Date</th>
                  <th>RSP Address</th>
                  <th>RSPA Address</th>
                  <th>RSPR Address</th>
                  <th>Region</th>
                  <th>Select</th>
                </tr>
              </thead>

              <tbody>
                {myDeployments.deployments.map(
                  (deployment: any, index: number) => {
                    return (
                      <tr key={index} style={{ cursor: "pointer" }}>
                        <td>{deployment.name}</td>
                        <td>
                          <AddressItem address={deployment.address} />
                        </td>
                        <td>{getDate(deployment.dateCreated)}</td>
                        <td>
                          <AddressItem address={deployment.rspAddress} />
                        </td>
                        <td>
                          <AddressItem address={deployment.rspaAddress} />
                        </td>
                        <td>
                          <AddressItem address={deployment.rsprAddress} />
                        </td>
                        <td>{deployment.region}</td>
                        <td align="center">
                          <Form.Check
                            type={"checkbox"}
                            id={`default`}
                            onClick={(event) => {
                              onSelect(event, deployment);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <h5 className="mt-3">Blockchain Data</h5>
      <div>
        <Row>
          <Col lg={3}>
            <Card>
              <Form.Check
                type={"checkbox"}
                id={`default`}
                onClick={(event) => {
                  onBlockChainSelect(event, ETHEREUM);
                }}
                className="crypto-brand-select"
              />
              <div className="blockchain-card">
                <img
                  src={ethereumIcon}
                  alt="eth"
                  className="crypto-brand-icon"
                />
                <h5>Ethereum Mainnet</h5>
              </div>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <Form.Check
                type={"checkbox"}
                id={`default`}
                onClick={(event) => {
                  onBlockChainSelect(event, APTOS);
                }}
                className="crypto-brand-select"
              />
              <div className="blockchain-card">
                <img src={aptosIcon} alt="eth" className="crypto-brand-icon" />
                <h5>Aptos</h5>
              </div>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <Form.Check
                type={"checkbox"}
                id={`default`}
                onClick={(event) => {
                  onBlockChainSelect(event, POLYGON);
                }}
                className="crypto-brand-select"
              />
              <div className="blockchain-card">
                <img
                  src={polygonIcon}
                  alt="eth"
                  className="crypto-brand-icon"
                />
                <h5>Polygon Mumbai</h5>
              </div>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <Form.Check
                type={"checkbox"}
                id={`default`}
                onClick={(event) => {
                  onBlockChainSelect(event, SOLANA);
                }}
                className="crypto-brand-select"
              />
              <div className="blockchain-card">
                <img src={solanaIcon} alt="eth" className="crypto-brand-icon" />
                <h5>Solana</h5>
              </div>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <Form.Check
                type={"checkbox"}
                id={`default`}
                onClick={(event) => {
                  onBlockChainSelect(event, BNB);
                }}
                className="crypto-brand-select"
              />
              <div className="blockchain-card">
                <img src={bnbIcon} alt="eth" className="crypto-brand-icon" />
                <h5>BNB Chain</h5>
              </div>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <Form.Check
                type={"checkbox"}
                id={`default`}
                onClick={(event) => {
                  onBlockChainSelect(event, AVALANCHE);
                }}
                className="crypto-brand-select"
              />
              <div className="blockchain-card">
                <img
                  src={avalancheIcon}
                  alt="eth"
                  className="crypto-brand-icon"
                />
                <h5>Avalanche</h5>
              </div>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <Form.Check
                type={"checkbox"}
                id={`default`}
                onClick={(event) => {
                  onBlockChainSelect(event, POLKADOT);
                }}
                className="crypto-brand-select"
              />
              <div className="blockchain-card">
                <img
                  src={polkadotIcon}
                  alt="eth"
                  className="crypto-brand-icon"
                />
                <h5>Polkadot</h5>
              </div>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <Form.Check
                type={"checkbox"}
                id={`default`}
                onClick={(event) => {
                  onBlockChainSelect(event, CARDANO);
                }}
                className="crypto-brand-select"
              />
              <div className="blockchain-card">
                <img
                  src={cardanoIcon}
                  alt="eth"
                  className="crypto-brand-icon"
                />
                <h5>Cardano</h5>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <h5 className="mt-3">File Loader</h5>
      <FormInput
        type="file"
        name="file"
        className="form-control"
        placeholder="Add File"
        containerClass={"mb-3"}
        key="file"
        onChange={(event) => {
          onValueChange(event, "file");
        }}
      />

      <h5 className="mt-3">External API</h5>
      <FormInput
        type="text"
        name="api"
        className="form-control"
        placeholder="Enter External API"
        containerClass={"mb-3"}
        key="api"
        onChange={(event) => {
          onValueChange(event, "api");
        }}
        value={api}
      />

      <h5 className="mt-3">Amazon S3</h5>
      <FormInput
        type="text"
        name="amazon"
        className="form-control"
        placeholder="Enter Amazon S3 link"
        containerClass={"mb-3"}
        key="amazon"
        onChange={(event) => {
          onValueChange(event, "amazon");
        }}
        value={amazon}
      />

      <p>
        (Don't see your data source in this list? Make a proposal by filling
        this form)
      </p>

      <div style={{ float: "right" }}>
        <Button
          style={{ marginRight: "8px" }}
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button onClick={onCreateClick} className="btn-cta">
          Create
        </Button>
      </div>
    </div>
  );
};

export default CreateDataLake;
