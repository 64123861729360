import React, { useEffect } from "react";
import { Nav, Navbar, Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// store
import { RootState } from "../../redux/store";

// images
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import { TOKEN_ADDRESS } from "../dashboard/StakingProfile/constants";

import { useSDK } from "@metamask/sdk-react";
import { ToastContainer, toast } from "react-toastify";

const NavBar = () => {
  const { provider } = useSDK();

  const { user, userLoggedIn } = useSelector((state: RootState) => ({
    user: state.Auth.user,
    loading: state.Auth.loading,
    error: state.Auth.error,
    userLoggedIn: state.Auth.userLoggedIn,
  }));

  const onTokenAdd = async () => {
    const tokenAddress = TOKEN_ADDRESS;
    const tokenSymbol = "TTKAN";
    const tokenDecimals = 18;
    // const tokenImage = "http://placekitten.com/200/300";

    try {
      // 'wasAdded' is a boolean. Like any RPC method, an error can be thrown.
      const wasAdded = await provider?.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            // The address of the token.
            address: tokenAddress,
            // A ticker symbol or shorthand, up to 5 characters.
            symbol: tokenSymbol,
            // The number of decimals in the token.
            decimals: tokenDecimals,
            // A string URL of the token logo.
            image: "",
          },
        },
      });

      if (wasAdded) {
        toast.success("Token Info Added!", {
          position: "top-right",
        });
      } else {
        toast.error("Something went wrong! Please try again later", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // on scroll add navbar class
  useEffect(() => {
    // manage scroll
    const navbar = document.getElementById("nav-menu");

    window.addEventListener("scroll", (e) => {
      e.preventDefault();
      if (navbar) {
        if (
          document.body.scrollTop >= 50 ||
          document.documentElement.scrollTop >= 50
        ) {
          navbar.classList.add("nav-sticky", "shadow");
        } else {
          navbar.classList.remove("nav-sticky", "shadow");
        }
      }
    });
  }, []);

  return (
    <>
      <ToastContainer autoClose={1000} theme="dark" hideProgressBar={true} />

      <Navbar
        collapseOnSelect
        expand={"lg"}
        className="py-lg-1 sticky-top"
        id="nav-menu"
      >
        <Container>
          <div className="auth-logo me-lg-5">
            <Link to="/" className="logo logo-dark">
              <span className="logo-lg">
                <img src={logoDark} alt="" className="me-2" height="24" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-lg">
                <img src={logoLight} alt="" className="me-2" height="24" />
              </span>
            </Link>
          </div>

          <Navbar.Toggle
            className="shadow-none border-0 px-0 text-dark"
            aria-controls="responsive-navbar-nav"
          >
            <i className="uil uil-bars"></i>
          </Navbar.Toggle>

          <Navbar.Collapse id="responsive-navbar-nav">
            {/* left menu */}
            <Nav as="ul" className="me-auto align-items-center"></Nav>

            {/* right menu */}
            <Nav as="ul" className="ms-auto align-items-lg-center fs-16">
              <Nav.Item as="li" className="mx-lg-1">
                <button
                  className="btn btn-secondary btn-cta shadow-sm"
                  onClick={onTokenAdd}
                >
                  Add Token Info
                </button>
              </Nav.Item>

              <Nav.Item as="li" className="mx-lg-1">
                {userLoggedIn || user ? (
                  <Nav.Link
                    as={Link}
                    to="/auth/logout"
                    className="text-dark link-demos"
                  >
                    Logout
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    as={Link}
                    to="/auth/login"
                    className="text-dark link-demos"
                  >
                    <button className="btn btn-secondary shadow-sm">
                      Sign In
                    </button>
                  </Nav.Link>
                )}
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
