import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getDataLakesList } from "../../../redux/actions";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import { Alert, Button } from "react-bootstrap";

import FeatherIcons from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import AddressItem from "../../../components/AddressItem";
import { getDate } from "../../../helpers/helperMethods";

const DataLakes = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const {
    loading,
    error,
    dataLakes,
    getDataLakesSuccess,
    dataLakeDetails,
    detailsSuccess,
    detailsLoading,
  } = useSelector((state: RootState) => ({
    loading: state.Datalake.loading,
    error: state.Datalake.error,
    dataLakes: state.Datalake.dataLakes,
    getDataLakesSuccess: state.Datalake.getDataLakesSuccess,
    dataLakeDetails: state.Datalake.dataLakeDetails,
    detailsSuccess: state.Datalake.detailsSuccess,
    detailsLoading: state.Datalake.detailsLoading,
  }));

  const onCreate = () => {
    navigate("/create-data-lake");
  };

  const onViewDetails = (datalake: any) => {
    let datalakeValues = JSON.stringify(datalake);
    navigate("/datalake-details", {
      state: datalakeValues,
    });
  };

  useEffect(() => {
    dispatch(getDataLakesList());
  }, [dispatch]);

  return (
    <section className="py-3 bg-body border-top">
      {(loading || detailsLoading) && <Loader />}

      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}

      <div style={{ float: "right", marginBottom: "1rem" }}>
        <Button onClick={onCreate} className="btn-cta">
          Create New Data Lake
        </Button>
      </div>

      {getDataLakesSuccess && dataLakes.length !== 0 && (
        <table className="table table-sm table-centered mt-2 mb-0 fs-13">
          <thead className="table-light">
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Date Created</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {dataLakes.map((datalake: any, index: number) => {
              return (
                <tr style={{ cursor: "pointer" }}>
                  <td>{datalake.name}</td>
                  <td>
                    <AddressItem address={datalake.address} />
                  </td>
                  <td>{getDate(datalake.dateCreated)}</td>
                  <td>
                    <Button
                      onClick={() => {
                        onViewDetails(datalake);
                      }}
                      className="btn-cta"
                      size="sm"
                    >
                      View Details
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </section>
  );
};

export default DataLakes;
