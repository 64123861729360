import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
  validateCode as validateCodeApi,
  listKeys as listKeysApi,
  saveNonCustodianKeys as saveNonCustodianKeysApi,
  saveTermsAndConditions as saveTermsAndConditionsApi,
} from "../../helpers/";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";

interface UserData {
  payload: {
    username: string;
    password: string;
    firstName: string;
    email: string;
    lastName: string;
    name: string;
    role: string;
    code: string;
    publicKey: string;
    address: string;
    userType: string;
    terms: any;
    signature: any;
  };
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({
  payload: { username, password },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(loginApi, { username, password });
    const user = response.data;

    if (user.error === "") {
      yield put(
        authApiResponseError(
          AuthActionTypes.LOGIN_USER,
          "Something went wrong!"
        )
      );
      api.setLoggedInUser(null);
      setAuthorization(null);
    } else {
      yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));

      api.setLoggedInUser(user);
      setAuthorization("success");
    }

    // NOTE - You can change this according to response format from your api
  } catch (error: any) {
    console.log("error", error);
    // yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    yield call(logoutApi);
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

/**
 * Register new user
 * @param {*} payload - email, password, firstName, lastName, name, role
 */
function* signup({
  payload: { email, password, firstName, lastName, name, role, userType },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, {
      email,
      password,
      firstName,
      lastName,
      name,
      role,
      userType,
    });
    const user = response.data;
    api.setLoggedInUser(user);
    // setAuthorization(user['token']);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Validate user email
 * @param {*} payload - email, code
 */
function* validateCode({ payload: { email, code } }: UserData): SagaIterator {
  try {
    const response = yield call(validateCodeApi, { email, code });
    yield put(
      authApiResponseSuccess(AuthActionTypes.VALIDATE_CODE, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.VALIDATE_CODE, error));
  }
}

/**
 * List user key sets
 */
function* listKeys(): SagaIterator {
  try {
    const response = yield call(listKeysApi);
    yield put(authApiResponseSuccess(AuthActionTypes.LIST_KEYS, response.data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LIST_KEYS, error));
  }
}

/**
 * Save non custodian key set
 * * @param {*} payload - publicKey, address
 */
function* saveNonCustodianKeys({
  payload: { publicKey, address },
}: UserData): SagaIterator {
  try {
    const response = yield call(saveNonCustodianKeysApi, {
      publicKey,
      address,
    });
    yield put(
      authApiResponseSuccess(
        AuthActionTypes.SAVE_NON_CUSTODIAN_KEY,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      authApiResponseError(AuthActionTypes.SAVE_NON_CUSTODIAN_KEY, error)
    );
  }
}

/**
 * Save terms and conditions for db node provider
 * * @param {*} payload - terms, signature
 */
function* saveTermsAndConditions({
  payload: { terms, signature },
}: UserData): SagaIterator {
  try {
    const response = yield call(saveTermsAndConditionsApi, {
      terms,
      signature,
    });
    yield put(
      authApiResponseSuccess(
        AuthActionTypes.SAVE_TERMS_AND_CONDITIONS,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      authApiResponseError(AuthActionTypes.SAVE_TERMS_AND_CONDITIONS, error)
    );
  }
}

/**
 * User forgot password
 * @param {*} payload - username
 */
function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchValidateCode(): any {
  yield takeEvery(AuthActionTypes.VALIDATE_CODE, validateCode);
}

export function* watchListKeys(): any {
  yield takeEvery(AuthActionTypes.LIST_KEYS, listKeys);
}

export function* watchSaveNonCustodianKey(): any {
  yield takeEvery(AuthActionTypes.SAVE_NON_CUSTODIAN_KEY, saveNonCustodianKeys);
}

export function* watchSaveTermsAndConditions(): any {
  yield takeEvery(
    AuthActionTypes.SAVE_TERMS_AND_CONDITIONS,
    saveTermsAndConditions
  );
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchValidateCode),
    fork(watchListKeys),
    fork(watchSaveNonCustodianKey),
    fork(watchSaveTermsAndConditions),
  ]);
}

export default authSaga;
