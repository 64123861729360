export enum StakeActionTypes {
  API_RESPONSE_SUCCESS = "@@stakes/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@stakes/API_RESPONSE_ERROR",

  FETCH_STAKE_DETAILS = "@@stakes/FETCH_STAKE_DETAILS",
  SAVE_STAKE_TRANSACTION = "@@stakes/SAVE_STAKE_TRANSACTION",

  GET_DB_NODES = "@@stakes/GET_DB_NODES",
  SAVE_DB_NODES = "@@stakes/SAVE_DB_NODES",

  GET_USER_TERMS_AND_CONDITIONS = "@@stakes/GET_USER_TERMS_AND_CONDITIONS",

  RESET = "@@stakes/RESET",
}
