export enum AuthActionTypes {
  API_RESPONSE_SUCCESS = "@@auth/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@auth/API_RESPONSE_ERROR",

  LOGIN_USER = "@@auth/LOGIN_USER",
  LOGOUT_USER = "@@auth/LOGOUT_USER",
  SIGNUP_USER = "@@auth/SIGNUP_USER",
  FORGOT_PASSWORD = "@@auth/FORGOT_PASSWORD",
  FORGOT_PASSWORD_CHANGE = "@@auth/FORGOT_PASSWORD_CHANGE",
  VALIDATE_CODE = "@@auth/VALIDATE_CODE",

  LIST_KEYS = "@@auth/LIST_KEYS",
  SAVE_NON_CUSTODIAN_KEY = "@@auth/SAVE_NON_CUSTODIAN_KEY",

  SAVE_TERMS_AND_CONDITIONS = "@@auth/SAVE_TERMS_AND_CONDITIONS",

  RESET = "@@auth/RESET",
}
