import { Alert, Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { fetchMyDeployments } from "../../../../redux/actions";
import { useEffect } from "react";
import { getDate } from "../../../../helpers/helperMethods";
import Loader from "../../../../components/Loader";
import { useNavigate } from "react-router-dom";

const MyDeployments = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { error, myDeployments, getDeploymentSuccess, getDeploymentLoading } =
    useSelector((state: RootState) => ({
      error: state.Proposal.error,
      myDeployments: state.Proposal.myDeployments,
      getDeploymentSuccess: state.Proposal.getDeploymentSuccess,
      getDeploymentLoading: state.Proposal.getDeploymentLoading,
    }));

  const onViewDeployment = (deployment: any) => {
    navigate("/deployment-details/" + deployment.rspaAddress);
  };

  useEffect(() => {
    dispatch(fetchMyDeployments());
  }, [dispatch]);

  return (
    <Card className="mt-2">
      {getDeploymentLoading && <Loader />}

      <Card.Body>
        <h4 className="card-title header-title">Deployments</h4>

        {error && (
          <Alert variant="danger" className="my-2" dismissible>
            {error}
          </Alert>
        )}

        {getDeploymentSuccess && myDeployments?.deployments.length !== 0 && (
          <div className="table-responsive mt-3">
            <table className="table table-sm table-centered mb-0 fs-13">
              <thead className="table-light">
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Date</th>
                  <th>CPU Spec</th>
                  <th>Memory</th>
                  <th>Redundancy</th>
                  <th>Region</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {myDeployments.deployments.map(
                  (deployment: any, index: number) => {
                    return (
                      <tr key={index} style={{ cursor: "pointer" }}>
                        <td>{deployment.name}</td>
                        <td>{deployment.price}</td>
                        <td>{getDate(deployment.dateCreated)}</td>
                        <td>{deployment.cpu}</td>
                        <td>{deployment.memory} GB</td>
                        <td align="center">{deployment.redundancy}</td>
                        <td>{deployment.region}</td>
                        <td>
                          <Button
                            onClick={() => {
                              onViewDeployment(deployment);
                            }}
                            className="btn-cta"
                            size="sm"
                          >
                            View Deployment
                          </Button>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default MyDeployments;
