// apicore
import { APICore } from "../../helpers/api/apiCore";

// constants
import { ProposalActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  user: api.getLoggedInUser(),
  loading: false,
  proposals: [],
};

interface ProposalData {
  message: string;
  secret: any;
}

interface AuthActionType {
  type:
    | ProposalActionTypes.API_RESPONSE_SUCCESS
    | ProposalActionTypes.API_RESPONSE_ERROR
    | ProposalActionTypes.SAVE_REQUEST_FOR_PROPOSAL
    | ProposalActionTypes.GET_PROPOSALS_LIST
    | ProposalActionTypes.DB_ENGINE_VALUES
    | ProposalActionTypes.CPU_SPEC_VALUES
    | ProposalActionTypes.REGION_VALUES
    | ProposalActionTypes.GET_RESPONSE_FOR_PROPOSAL
    | ProposalActionTypes.GET_ACTIVE_PROPOSALS
    | ProposalActionTypes.SAVE_BID_FOR_PROPOSAL
    | ProposalActionTypes.ACCEPT_PROPOSAL_BID
    | ProposalActionTypes.FETCH_ACCEPTED_BIDS
    | ProposalActionTypes.CREATE_DEPLOYMENT
    | ProposalActionTypes.FETCH_DEPLOYMENT_DETAILS
    | ProposalActionTypes.CREATE_NODE
    | ProposalActionTypes.GET_NODES_FOR_DEPLOYMENT
    | ProposalActionTypes.EDIT_NODE
    | ProposalActionTypes.DELETE_NODE
    | ProposalActionTypes.GET_MY_DEPLOYMENTS;
  payload: {
    actionType?: string;
    data?: ProposalData | {};
    error?: string;
  };
}

interface State {
  proposals?: ProposalData | {};
  activeProposals?: ProposalData | {};
  loading?: boolean;
  value?: boolean;
}

const Proposal = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case ProposalActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ProposalActionTypes.GET_PROPOSALS_LIST: {
          return {
            ...state,
            proposals: action.payload.data,
            loading: false,
          };
        }
        case ProposalActionTypes.GET_ACTIVE_PROPOSALS: {
          return {
            ...state,
            activeProposals: action.payload.data,
            loading: false,
          };
        }
        case ProposalActionTypes.DB_ENGINE_VALUES: {
          return {
            ...state,
            dbEngineValues: action.payload.data,
            loading: false,
          };
        }
        case ProposalActionTypes.CPU_SPEC_VALUES: {
          return {
            ...state,
            cpuSpecValues: action.payload.data,
            loading: false,
          };
        }
        case ProposalActionTypes.REGION_VALUES: {
          return {
            ...state,
            regionValues: action.payload.data,
            loading: false,
          };
        }
        case ProposalActionTypes.SAVE_REQUEST_FOR_PROPOSAL: {
          return {
            ...state,
            saveLoading: false,
            proposalSaved: true,
          };
        }
        case ProposalActionTypes.GET_RESPONSE_FOR_PROPOSAL: {
          return {
            ...state,
            proposalResponse: action.payload.data,
            rspSuccess: true,
            rspLoading: false,
          };
        }
        case ProposalActionTypes.SAVE_BID_FOR_PROPOSAL: {
          return {
            ...state,
            bidSaved: true,
            bidSaveLoading: false,
          };
        }
        case ProposalActionTypes.ACCEPT_PROPOSAL_BID: {
          return {
            ...state,
            bidAccepted: true,
            bidAcceptLoading: false,
          };
        }
        case ProposalActionTypes.FETCH_ACCEPTED_BIDS: {
          return {
            ...state,
            acceptedBids: action.payload.data,
            bidsSuccess: true,
            bidsLoading: false,
          };
        }

        case ProposalActionTypes.CREATE_DEPLOYMENT: {
          return {
            ...state,
            deployment: action.payload.data,
            createSuccess: true,
            createLoading: false,
          };
        }

        case ProposalActionTypes.FETCH_DEPLOYMENT_DETAILS: {
          return {
            ...state,
            deploymentDetails: action.payload.data,
            detailsSuccess: true,
            detailsLoading: false,
          };
        }

        case ProposalActionTypes.CREATE_NODE: {
          return {
            ...state,
            nodeCreated: true,
            nodeCreateLoading: false,
            nodesSuccess: false,
          };
        }

        case ProposalActionTypes.GET_NODES_FOR_DEPLOYMENT: {
          return {
            ...state,
            nodeDetails: action.payload.data,
            nodesSuccess: true,
            nodesLoading: false,
          };
        }

        case ProposalActionTypes.EDIT_NODE: {
          return {
            ...state,
            editNodeSuccess: true,
            editNodeLoading: false,
            nodesSuccess: false,
          };
        }

        case ProposalActionTypes.DELETE_NODE: {
          return {
            ...state,
            deleteSuccess: true,
            deleteLoading: false,
            nodesSuccess: false,
          };
        }

        case ProposalActionTypes.GET_MY_DEPLOYMENTS: {
          return {
            ...state,
            myDeployments: action.payload.data,
            getDeploymentSuccess: true,
            getDeploymentLoading: false,
          };
        }

        default:
          return { ...state };
      }

    case ProposalActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ProposalActionTypes.GET_PROPOSALS_LIST: {
          return {
            ...state,
            loading: false,
            error: action.payload.data,
          };
        }
        case ProposalActionTypes.GET_ACTIVE_PROPOSALS: {
          return {
            ...state,
            loading: false,
            error: action.payload.data,
          };
        }
        case ProposalActionTypes.SAVE_REQUEST_FOR_PROPOSAL: {
          return {
            ...state,
            saveLoading: false,
            proposalSaved: false,
            error: action.payload.data,
          };
        }
        case ProposalActionTypes.SAVE_BID_FOR_PROPOSAL: {
          return {
            ...state,
            bidSaved: false,
            bidSaveLoading: false,
            error: action.payload.data,
          };
        }
        case ProposalActionTypes.ACCEPT_PROPOSAL_BID: {
          return {
            ...state,
            bidAccepted: false,
            bidAcceptLoading: false,
            error: action.payload.data,
          };
        }
        case ProposalActionTypes.FETCH_ACCEPTED_BIDS: {
          return {
            ...state,
            error: action.payload.data,
            bidsSuccess: false,
            bidsLoading: false,
          };
        }
        case ProposalActionTypes.CREATE_DEPLOYMENT: {
          return {
            ...state,
            error: action.payload.data,
            createSuccess: false,
            createLoading: false,
          };
        }
        case ProposalActionTypes.FETCH_DEPLOYMENT_DETAILS: {
          return {
            ...state,
            error: action.payload.data,
            detailsSuccess: false,
            detailsLoading: false,
          };
        }

        case ProposalActionTypes.CREATE_NODE: {
          return {
            ...state,
            error: action.payload.data,
            nodeCreated: false,
            nodeCreateLoading: false,
          };
        }

        case ProposalActionTypes.GET_NODES_FOR_DEPLOYMENT: {
          return {
            ...state,
            error: action.payload.data,
            nodesSuccess: false,
            nodesLoading: false,
          };
        }

        case ProposalActionTypes.EDIT_NODE: {
          return {
            ...state,
            editNodeSuccess: false,
            editNodeLoading: false,
            error: action.payload.data,
          };
        }

        case ProposalActionTypes.DELETE_NODE: {
          return {
            ...state,
            deleteSuccess: false,
            deleteLoading: false,
            error: action.payload.data,
          };
        }

        case ProposalActionTypes.GET_MY_DEPLOYMENTS: {
          return {
            ...state,
            error: action.payload.data,
            getDeploymentSuccess: false,
            getDeploymentLoading: false,
          };
        }

        default:
          return { ...state };
      }

    case ProposalActionTypes.GET_PROPOSALS_LIST:
      return { ...state, loading: true, proposals: [], proposalSaved: "" };

    case ProposalActionTypes.GET_ACTIVE_PROPOSALS:
      return {
        ...state,
        loading: true,
        activeProposals: [],
        proposalSaved: "",
        bidSaved: false,
      };

    case ProposalActionTypes.SAVE_REQUEST_FOR_PROPOSAL:
      return { ...state, saveLoading: true, proposalSaved: "" };

    case ProposalActionTypes.SAVE_BID_FOR_PROPOSAL: {
      return {
        ...state,
        bidSaved: "",
        bidSaveLoading: true,
      };
    }

    case ProposalActionTypes.GET_RESPONSE_FOR_PROPOSAL:
      return {
        ...state,
        rspSuccess: false,
        rspLoading: true,
        proposalResponse: "",
      };

    case ProposalActionTypes.ACCEPT_PROPOSAL_BID: {
      return {
        ...state,
        bidAccepted: "",
        bidAcceptLoading: true,
      };
    }

    case ProposalActionTypes.FETCH_ACCEPTED_BIDS: {
      return {
        ...state,
        acceptedBids: "",
        bidsSuccess: false,
        bidsLoading: true,
      };
    }

    case ProposalActionTypes.CREATE_DEPLOYMENT: {
      return {
        ...state,
        deployment: "",
        createSuccess: false,
        createLoading: true,
      };
    }

    case ProposalActionTypes.FETCH_DEPLOYMENT_DETAILS: {
      return {
        ...state,
        deploymentDetails: "",
        detailsSuccess: false,
        detailsLoading: true,
        nodeCreated: false,
        nodeCreateLoading: false,
      };
    }

    case ProposalActionTypes.CREATE_NODE: {
      return {
        ...state,
        nodeCreated: false,
        nodeCreateLoading: true,
      };
    }

    case ProposalActionTypes.GET_NODES_FOR_DEPLOYMENT: {
      return {
        ...state,
        nodeDetails: "",
        nodesSuccess: false,
        nodesLoading: true,
        deleteSuccess: false,
        editNodeSuccess: false,
      };
    }

    case ProposalActionTypes.EDIT_NODE: {
      return {
        ...state,
        editNodeSuccess: false,
        editNodeLoading: true,
      };
    }

    case ProposalActionTypes.DELETE_NODE: {
      return {
        ...state,
        deleteSuccess: false,
        deleteLoading: true,
      };
    }

    case ProposalActionTypes.GET_MY_DEPLOYMENTS: {
      return {
        ...state,
        myDeployments: "",
        getDeploymentSuccess: false,
        getDeploymentLoading: true,
      };
    }

    default:
      return { ...state };
  }
};

export default Proposal;
