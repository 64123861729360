import { Alert, Button, Card, Col, Row, Tab } from "react-bootstrap";

import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../../redux/store";
import { listKeys } from "../../../../redux/actions";

import ViewKeys from "./ViewKeys";
import Loader from "../../../../components/Loader";
import { useNavigate } from "react-router-dom";
import AddressItem from "../../../../components/AddressItem";

const ListKeys = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { loading, error, keys } = useSelector((state: RootState) => ({
    loading: state.Auth.loading,
    error: state.Auth.error,
    keys: state.Auth.keys,
  }));

  const onCreateClick = () => {
    navigate("/create-keys/secondary");
  };

  useEffect(() => {
    dispatch(listKeys());
  }, [dispatch]);

  return (
    <>
      {loading && <Loader />}

      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}

      <Card className="mt-2">
        <Card.Body>
          <Tab.Container id="left-tabs-example">
            <h4 className="card-title header-title">API</h4>

            {!loading && keys && keys.status && (
              <Row className="justify-content-between align-items-end pt-2">
                <Col md={12} xs={12}>
                  <h6 className="my-2">
                    API Key - <AddressItem address={keys.message.apiKey} />
                  </h6>
                  <h6 className="my-2">
                    API Secret -{" "}
                    <AddressItem address={keys.message.apiSecret} />
                  </h6>
                </Col>
              </Row>
            )}
          </Tab.Container>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Card.Body>
          <Tab.Container id="left-tabs-example">
            <h4 className="card-title header-title">Primary Key</h4>

            {!loading && keys && keys.status && (
              <ViewKeys createdKeys={keys.message.key} />
            )}
          </Tab.Container>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Card.Body>
          <Tab.Container id="left-tabs-example">
            <div className="table-data-header-div">
              <h4 className="card-title header-title">Deployment Keys</h4>
              <Button onClick={onCreateClick}>Create Key</Button>
            </div>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default ListKeys;
