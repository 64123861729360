import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  getStakeTransactionDetails,
  saveStakeTransaction,
} from "../../../redux/actions";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { FormInput } from "../../../components";

import { DEPOSIT_ADDRESS } from "./constants";
import { getActualStakeAmount, getDate } from "../../../helpers/helperMethods";

import noStake from "../../../assets/images/layouts/stake.svg";
import AddressItem from "../../../components/AddressItem";

import FeatherIcons from "feather-icons-react";

const Stakes = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [showStakeForm, setShowStakeForm] = useState(false);
  const [txHash, setTxHash] = useState(0);
  const [nodes, setNodes] = useState(0);
  const [showTxnHistory, setShowTxnHistory] = useState(true);

  const { loading, stakeDetails, saveStakeLoading, stakeTxSaved, error } =
    useSelector((state: RootState) => ({
      loading: state.Stakes.loading,
      stakeDetails: state.Stakes.stakeDetails,
      saveStakeLoading: state.Stakes.saveStakeLoading,
      stakeTxSaved: state.Stakes.stakeTxSaved,
      error: state.Stakes.error,
    }));

  const onStakeNowClick = () => {
    setShowStakeForm(true);
    setShowTxnHistory(false);
  };

  const onExecuteClick = () => {
    dispatch(saveStakeTransaction(nodes, txHash));
  };

  const onValueChange = (event: any, type: string) => {
    let value = event.target.value;

    if (type === "txHash") {
      setTxHash(value);
    } else {
      setNodes(value);
    }
  };

  const onRefreshStake = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setShowTxnHistory(false);

    dispatch(getStakeTransactionDetails());
  };

  const onShowTransactionHistory = () => {
    setShowTxnHistory(true);
  };

  const onStakeSaved = () => {
    setShowStakeForm(false);
    setShowTxnHistory(true);

    dispatch(getStakeTransactionDetails());
  };

  const getAmount = () => {
    return nodes * 3000;
  };

  const onCancel = () => {
    setShowStakeForm(false);
  };

  useEffect(() => {
    dispatch(getStakeTransactionDetails());
  }, [dispatch]);

  return (
    <section className="py-3 bg-body border-top">
      {(loading || saveStakeLoading) && <Loader />}

      {stakeTxSaved && (
        <Alert variant="success" className="my-2">
          <>
            Stake Executed Successfully!
            {onStakeSaved()}
          </>
        </Alert>
      )}

      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}

      {!loading &&
        stakeDetails &&
        stakeDetails.status === "success" &&
        (stakeDetails.message.accountStake.user ? (
          <div>
            <Card onClick={onShowTransactionHistory}>
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  cursor: "pointer",
                }}
              >
                <div>
                  <h4>
                    Address :{" "}
                    <AddressItem
                      address={stakeDetails.message.accountStake.user || ""}
                    />
                  </h4>

                  <h4>
                    Stake Amount :{" "}
                    {getActualStakeAmount(
                      stakeDetails.message.accountStake.stakeAmount
                    )}
                  </h4>
                </div>

                <Button
                  onClick={(event) => {
                    onRefreshStake(event);
                  }}
                  className="btn-cta"
                >
                  <FeatherIcons
                    icon={"refresh-ccw"}
                    className="icon-dual"
                    size={15}
                    style={{
                      marginLeft: "4px",
                      cursor: "pointer",
                      color: "#fff",
                    }}
                  />
                </Button>
              </Card.Body>
            </Card>

            {!showStakeForm && (
              <Button onClick={onStakeNowClick} className="btn-cta">
                Stake More
              </Button>
            )}
          </div>
        ) : (
          !showStakeForm && (
            <div className="no-stake-div">
              <img src={noStake} alt="No stake" width={"20%"} />
              <Button onClick={onStakeNowClick} className="btn-cta">
                No Stakes! Stake Now
              </Button>
            </div>
          )
        ))}

      {showStakeForm && (
        <Container>
          <Row>
            <Col md={12} className="mb-1">
              <p className="fs-16">
                Kandola Staking deposit address :{" "}
                <b style={{ color: "#D84C2C" }}>{DEPOSIT_ADDRESS}</b>
              </p>

              <p className="fs-16">
                Minimum Staking Amount : <b>3000 TTKAN</b>
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <FormInput
                type="text"
                name="nodes"
                label={"No of Nodes you want to run"}
                placeholder={""}
                containerClass={"mb-3"}
                onChange={(event) => {
                  onValueChange(event, "nodes");
                }}
                value={nodes}
              />
            </Col>
          </Row>

          {nodes !== 0 && (
            <>
              <div className="mb-2">
                You need to deposit <b>{getAmount()}</b> into the above
                mentioned Kandola Staking Address. Once the transaction is
                confirmed, please paste the transaction hash in the text box
                below. Please ensure that the amount stakes is more than the min
                amount you have to stake as mentioned. You can stake more that
                the mentioned amount. The more you stake the better the chances
                you have of gaining more rewards and revenue.
              </div>

              <Row>
                <Col md={6}>
                  <FormInput
                    type="text"
                    name="txHash"
                    label={"Your deposit transaction hash"}
                    placeholder={""}
                    containerClass={"mb-3"}
                    onChange={(event) => {
                      onValueChange(event, "txHash");
                    }}
                    value={txHash}
                  />
                </Col>

                <Col md={12}>
                  <Button
                    onClick={onCancel}
                    style={{ marginRight: "8px" }}
                    variant="secondary"
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={nodes === 0 || txHash === 0}
                    onClick={onExecuteClick}
                    className="btn-cta"
                  >
                    Execute
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Container>
      )}

      {showTxnHistory &&
        !loading &&
        stakeDetails &&
        stakeDetails.status === "success" &&
        stakeDetails.message.accountStake.user && (
          <table className="table table-sm table-centered mt-2 mb-0 fs-13">
            <thead className="table-light">
              <tr>
                <th>Stake Tx Hash</th>
                <th>Transaction Hash</th>
                <th>From</th>
                <th>To</th>
                <th>Gas Used</th>
                <th>Chain ID</th>
                <th>Block Number</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>

            <tbody>
              {stakeDetails.message.transactionList.map(
                (transaction: any, index: number) => {
                  return (
                    <tr style={{ cursor: "pointer" }}>
                      <td>
                        <AddressItem address={transaction.txHash} />
                      </td>
                      <td>
                        <AddressItem address={transaction.transactionHash} />
                      </td>
                      <td>
                        <AddressItem address={transaction.from} />
                      </td>
                      <td>
                        <AddressItem address={transaction.to} />
                      </td>
                      <td>{transaction.gasUsed}</td>
                      <td>{transaction.chainId}</td>
                      <td>{transaction.blockNumber}</td>
                      <td>{getActualStakeAmount(transaction.amount)}</td>
                      <td>{getDate(transaction.dateCreated)}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        )}
    </section>
  );
};

export default Stakes;
