import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveRequestForProposal as saveRequestForProposalApi,
  getProposalsList as getProposalsListApi,
  getCpuSpecValues as getCpuSpecValuesApi,
  getDbEngineValues as getDbEngineValuesApi,
  getRegionValues as getRegionValuesApi,
  getResponseForProposal as getResponseForProposalApi,
  getActiveProposalsList as getActiveProposalsListApi,
  saveBidForProposal as saveBidForProposalApi,
  acceptBidForProposal as acceptBidForProposalApi,
  fetchAcceptedBids as fetchAcceptedBidsApi,
  createDeployment as createDeploymentApi,
  getDeploymentDetails as getDeploymentDetailsApi,
  createNode as createNodeApi,
  getNodesForDeployment as getNodesForDeploymentApi,
  editNodeStatus as editNodeStatusApi,
  deleteNode as deleteNodeApi,
  getMyDeployments as getMyDeploymentsApi,
} from "../../helpers/";

// actions
import {
  proposalApiResponseError,
  proposalApiResponseSuccess,
} from "./actions";

// constants
import { ProposalActionTypes } from "./constants";

interface ProposalData {
  payload: {
    message: string;
    secret: string;
    address: string;
    rspraAddress: any;
    name: string;
    ipAddress: any;
    port: any;
    url: any;
    region: any;
    healthStatus: any;
    deploymentStatus: any;
    dbNodeName: any;
    description: any;
    deploymentAddress: any;
    response: any;
  };
  type: string;
}

/**
 * Save Request for Proposal
 * @param {*} payload - message, secret
 */
function* saveRequestForProposal({
  payload: { message, secret },
}: ProposalData): SagaIterator {
  try {
    const response = yield call(saveRequestForProposalApi, {
      message,
      secret,
    });

    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.SAVE_REQUEST_FOR_PROPOSAL,
        response
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(
        ProposalActionTypes.SAVE_REQUEST_FOR_PROPOSAL,
        error
      )
    );
  }
}

/**
 * List user saved proposals
 */
function* getProposalList(): SagaIterator {
  try {
    const response = yield call(getProposalsListApi);
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.GET_PROPOSALS_LIST,
        response.data.message
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(ProposalActionTypes.GET_PROPOSALS_LIST, error)
    );
  }
}

/**
 * List user active proposal list
 */
function* getActiveProposalsList(): SagaIterator {
  try {
    const response = yield call(getActiveProposalsListApi);
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.GET_ACTIVE_PROPOSALS,
        response.data.message.list
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(ProposalActionTypes.GET_ACTIVE_PROPOSALS, error)
    );
  }
}

/**
 * List DB Engine values
 */
function* getDbEngineValues(): SagaIterator {
  try {
    const response = yield call(getDbEngineValuesApi);
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.DB_ENGINE_VALUES,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(ProposalActionTypes.DB_ENGINE_VALUES, error)
    );
  }
}

/**
 * List CPU spec vaues
 */
function* getCpuSpecValues(): SagaIterator {
  try {
    const response = yield call(getCpuSpecValuesApi);
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.CPU_SPEC_VALUES,
        response.data.message
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(ProposalActionTypes.CPU_SPEC_VALUES, error)
    );
  }
}

/**
 * List region values
 */
function* getRegionValues(): SagaIterator {
  try {
    const response = yield call(getRegionValuesApi);
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.REGION_VALUES,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(ProposalActionTypes.REGION_VALUES, error)
    );
  }
}

/**
 * Get Response for Proposal
 */
function* getProposalResponse({
  payload: { address },
}: ProposalData): SagaIterator {
  try {
    const response = yield call(getResponseForProposalApi, { address });
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.GET_RESPONSE_FOR_PROPOSAL,
        response.data.message
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(
        ProposalActionTypes.GET_RESPONSE_FOR_PROPOSAL,
        error
      )
    );
  }
}

/**
 * Save Bid For Proposal
 * @param {*} payload - secret
 */
function* saveBidForProposal({
  payload: { secret, message },
}: ProposalData): SagaIterator {
  try {
    const response = yield call(saveBidForProposalApi, {
      secret,
      message,
    });

    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.SAVE_BID_FOR_PROPOSAL,
        response
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(ProposalActionTypes.SAVE_BID_FOR_PROPOSAL, error)
    );
  }
}

/**
 * Accept Bid For Proposal
 * @param {*} payload - secret, message
 */
function* accpetBidForProposal({
  payload: { secret, message },
}: ProposalData): SagaIterator {
  try {
    const response = yield call(acceptBidForProposalApi, {
      secret,
      message,
    });

    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.ACCEPT_PROPOSAL_BID,
        response
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(ProposalActionTypes.ACCEPT_PROPOSAL_BID, error)
    );
  }
}

/**
 * List Accepted Bids
 */
function* fetchAcceptedBids(): SagaIterator {
  try {
    const response = yield call(fetchAcceptedBidsApi);
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.FETCH_ACCEPTED_BIDS,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(ProposalActionTypes.FETCH_ACCEPTED_BIDS, error)
    );
  }
}

/**
 * Create Deployment
 * @param {*} payload - rspraAddress, name
 */
function* createDeployment({
  payload: { rspraAddress, name },
}: ProposalData): SagaIterator {
  try {
    const response = yield call(createDeploymentApi, {
      rspraAddress,
      name,
    });

    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.CREATE_DEPLOYMENT,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(ProposalActionTypes.CREATE_DEPLOYMENT, error)
    );
  }
}

/**
 * Get Deployment Details
 */
function* getDeploymentDetails({
  payload: { rspraAddress },
}: ProposalData): SagaIterator {
  try {
    const response = yield call(getDeploymentDetailsApi, { rspraAddress });
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.FETCH_DEPLOYMENT_DETAILS,
        response.data.message.deployment
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(
        ProposalActionTypes.FETCH_DEPLOYMENT_DETAILS,
        error
      )
    );
  }
}

/**
 * Create Node
 */
function* createNode({
  payload: {
    ipAddress,
    port,
    url,
    region,
    healthStatus,
    deploymentStatus,
    dbNodeName,
    description,
    deploymentAddress,
    response,
  },
}: ProposalData): SagaIterator {
  try {
    const responseObj = yield call(createNodeApi, {
      ipAddress,
      port,
      url,
      region,
      healthStatus,
      deploymentStatus,
      dbNodeName,
      description,
      deploymentAddress,
      response,
    });
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.CREATE_NODE,
        responseObj.data
      )
    );
  } catch (error: any) {
    yield put(proposalApiResponseError(ProposalActionTypes.CREATE_NODE, error));
  }
}

/**
 * Get Nodes for Deployment
 */
function* getNodesForDeployment({
  payload: { deploymentAddress },
}: ProposalData): SagaIterator {
  try {
    const response = yield call(getNodesForDeploymentApi, {
      deploymentAddress,
    });
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.GET_NODES_FOR_DEPLOYMENT,
        response.data.message
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(
        ProposalActionTypes.GET_NODES_FOR_DEPLOYMENT,
        error
      )
    );
  }
}

/**
 * Edit Node Status
 * @param {*} payload - healthStatus, deploymentStatus, address
 */
function* editNodeStatus({
  payload: { healthStatus, deploymentStatus, address },
}: ProposalData): SagaIterator {
  try {
    const response = yield call(editNodeStatusApi, {
      healthStatus,
      deploymentStatus,
      address,
    });

    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.EDIT_NODE,
        response.message
      )
    );
  } catch (error: any) {
    yield put(proposalApiResponseError(ProposalActionTypes.EDIT_NODE, error));
  }
}

/**
 * Delete Node
 * @param {*} payload -  address
 */
function* deleteNode({ payload: { address } }: ProposalData): SagaIterator {
  try {
    const response = yield call(deleteNodeApi, {
      address,
    });

    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.DELETE_NODE,
        response.message
      )
    );
  } catch (error: any) {
    yield put(proposalApiResponseError(ProposalActionTypes.DELETE_NODE, error));
  }
}

/**
 * List user deployments
 */
function* getMyDeployments(): SagaIterator {
  try {
    const response = yield call(getMyDeploymentsApi);
    yield put(
      proposalApiResponseSuccess(
        ProposalActionTypes.GET_MY_DEPLOYMENTS,
        response.data.message
      )
    );
  } catch (error: any) {
    yield put(
      proposalApiResponseError(ProposalActionTypes.GET_MY_DEPLOYMENTS, error)
    );
  }
}

export function* watchGetProposalList(): any {
  yield takeEvery(ProposalActionTypes.GET_PROPOSALS_LIST, getProposalList);
}

export function* watchGetActiveProposalsList(): any {
  yield takeEvery(
    ProposalActionTypes.GET_PROPOSALS_LIST,
    getActiveProposalsList
  );
}

export function* watchGetDbEngineValues(): any {
  yield takeEvery(ProposalActionTypes.DB_ENGINE_VALUES, getDbEngineValues);
}

export function* watchGetCpuSpecValues(): any {
  yield takeEvery(ProposalActionTypes.CPU_SPEC_VALUES, getCpuSpecValues);
}

export function* watchGetRegionValues(): any {
  yield takeEvery(ProposalActionTypes.REGION_VALUES, getRegionValues);
}

export function* watchSaveRequestForProposal(): any {
  yield takeEvery(
    ProposalActionTypes.SAVE_REQUEST_FOR_PROPOSAL,
    saveRequestForProposal
  );
}

export function* watchGetProposalResponse(): any {
  yield takeEvery(
    ProposalActionTypes.GET_RESPONSE_FOR_PROPOSAL,
    getProposalResponse
  );
}

export function* watchSaveBidForProposal(): any {
  yield takeEvery(
    ProposalActionTypes.SAVE_BID_FOR_PROPOSAL,
    saveBidForProposal
  );
}

export function* watchAcceptBidForProposal(): any {
  yield takeEvery(
    ProposalActionTypes.ACCEPT_PROPOSAL_BID,
    accpetBidForProposal
  );
}

export function* watchFetchAcceptedBids(): any {
  yield takeEvery(ProposalActionTypes.FETCH_ACCEPTED_BIDS, fetchAcceptedBids);
}

export function* watchCreateDeployment(): any {
  yield takeEvery(ProposalActionTypes.CREATE_DEPLOYMENT, createDeployment);
}

export function* watchFetchDeploymentDetails(): any {
  yield takeEvery(
    ProposalActionTypes.FETCH_DEPLOYMENT_DETAILS,
    getDeploymentDetails
  );
}

export function* watchCreateNode(): any {
  yield takeEvery(ProposalActionTypes.CREATE_NODE, createNode);
}

export function* watchNodesForDeployment(): any {
  yield takeEvery(
    ProposalActionTypes.GET_NODES_FOR_DEPLOYMENT,
    getNodesForDeployment
  );
}

export function* watchEditNodeStatus(): any {
  yield takeEvery(ProposalActionTypes.EDIT_NODE, editNodeStatus);
}

export function* watchDeleteNode(): any {
  yield takeEvery(ProposalActionTypes.DELETE_NODE, deleteNode);
}

export function* watchGetMyDeployments(): any {
  yield takeEvery(ProposalActionTypes.GET_MY_DEPLOYMENTS, getMyDeployments);
}

function* authSaga() {
  yield all([
    fork(watchGetProposalList),
    fork(watchSaveRequestForProposal),
    fork(watchGetCpuSpecValues),
    fork(watchGetDbEngineValues),
    fork(watchGetRegionValues),
    fork(watchGetProposalResponse),
    fork(watchGetActiveProposalsList),
    fork(watchSaveBidForProposal),
    fork(watchAcceptBidForProposal),
    fork(watchFetchAcceptedBids),
    fork(watchCreateDeployment),
    fork(watchFetchDeploymentDetails),
    fork(watchCreateNode),
    fork(watchNodesForDeployment),
    fork(watchDeleteNode),
    fork(watchEditNodeStatus),
    fork(watchGetMyDeployments),
  ]);
}

export default authSaga;
