import { Alert, Button, Col, Row, Tab } from "react-bootstrap";

import { Navigate, useLocation } from "react-router-dom";

import { useSDK } from "@metamask/sdk-react";
import { saveNonCustodianKeys } from "../../../../redux/actions";

import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useState } from "react";

import metamaskIcon from "../../../../assets/images/icons/metamask.png";
import binance from "../../../../assets/images/icons/binance.png";
import coinbase from "../../../../assets/images/icons/coinbase.png";
import trust from "../../../../assets/images/icons/trust.png";
import okx from "../../../../assets/images/icons/okx.png";

const CreateKeys = () => {
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();

  const { loading, error, keySaved, user } = useSelector(
    (state: RootState) => ({
      loading: state.Auth.loading,
      error: state.Auth.error,
      keySaved: state.Auth.keySaved,
      user: state.Auth.user,
    })
  );

  const [metamaskError, setMetamaskError] = useState("");

  const { sdk } = useSDK();

  const connect = async () => {
    try {
      await sdk?.connect().then((response) => {
        getPublicKey(response);
      });
    } catch (err: any) {
      console.warn(`failed to connect..`, err);
      setMetamaskError(err.message);
    }
  };

  const getPublicKey = async (accounts: any) => {
    try {
      await window?.ethereum
        ?.request({
          method: "eth_getEncryptionPublicKey",
          params: [accounts?.[0]],
        })
        .then((response) => {
          dispatch(saveNonCustodianKeys(response, accounts?.[0]));
        });
    } catch (err: any) {
      console.warn(`failed to connect..`, err);
      setMetamaskError(err.message);
    }
  };

  const onErrorTimeout = () => {
    setTimeout(() => {
      setMetamaskError("");
    }, 2000);
  };

  return (
    <>
      {(error || metamaskError !== "") && (
        <Alert variant="danger" className="my-2">
          {error || metamaskError}
          {onErrorTimeout()}
        </Alert>
      )}

      {location.pathname === "/create-keys" && keySaved ? (
        user.authority[0] === "ROLE_DB_NODE_PROVIDER" ? (
          <Navigate to={"/terms"}></Navigate>
        ) : (
          <Navigate to={"/dashboard"}></Navigate>
        )
      ) : null}

      <h6 className="h5 fs-24 mb-3 mt-3 text-center">Create Your Keys</h6>

      <Tab.Container id="left-tabs-example">
        <Row style={{ margin: "0 20px" }}>
          <Col xl={12} lg={12}>
            <Row className="create-row-box">
              <Col lg={4}>
                <h6 className="h5 fs-20 mb-3">Recommended</h6>
                <h6 className="h5 fs-16 mt-3">Using secure external wallets</h6>
              </Col>

              <Col lg={2}>
                <img
                  src={metamaskIcon}
                  alt="metamask"
                  style={{ width: "60%" }}
                />
              </Col>

              <Col lg={2}>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={binance} alt="binance" style={{ width: "20%" }} />
                  <img src={coinbase} alt="coinbase" style={{ width: "20%" }} />
                  <img src={trust} alt="trust" style={{ width: "20%" }} />
                  <img src={okx} alt="okx" style={{ width: "20%" }} />
                </div>

                <div className="mt-1" style={{ float: "right" }}>
                  ...Coming Soon
                </div>
              </Col>

              <Col
                lg={4}
                className="create-row-button"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <Button onClick={connect} className="btn-cta">
                    Connect to Metamask
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={12} xl={12} className="mt-5">
            <Row className="create-row-box">
              <Col lg={6}>
                <h6 className="h5 fs-20 mb-3">Others</h6>
                <h6 className="h5 fs-16 mt-3">
                  Using Kandola Custodial Wallet
                </h6>
              </Col>

              <Col lg={6} className="create-row-button">
                <Button disabled={loading} className="btn-cta">
                  Create Wallet
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default CreateKeys;
