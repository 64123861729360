import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// images
import backImg from "../../assets/images/covers/pattern.png";

import heroImg from "../../assets/images/hero.png";

const Hero = () => {
  return (
    <section
      className="hero-section position-relative overflow-hidden px-3 text-dark  pb-0"
      style={{ background: `url(${backImg}) center top` }}
    >
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>

      <Container>
        <Row className="align-items-center">
          <Col className="text-center mt-2">
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <img src={heroImg} alt="" width={"35%"} />
              </div>

              <h4
                className="mb-1 mt-2 text-center"
                style={{ fontSize: "30px" }}
              >
                The Web3 <span style={{ color: "#D84C2C" }}>Data</span> Layer
              </h4>

              <h6 style={{ marginBottom: "12px", fontSize: "18px" }}>
                <span style={{ color: "#D84C2C" }}>Incentivised</span> Testnet
                Phase 1
              </h6>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
