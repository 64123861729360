import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import proposalSaga from "./proposals/saga";
import stakeSaga from "./stakes/saga";
import datalakeSaga from "./datalake/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    layoutSaga(),
    proposalSaga(),
    stakeSaga(),
    datalakeSaga(),
  ]);
}
