import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { username: string; password: string }) {
  var form = new FormData();
  form.append("username", params.username);
  form.append("password", params.password);

  const baseUrl = "/login/authenticate";
  return api.create(`${baseUrl}`, form);
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  name: string;
  role: string;
  userType: string;
}) {
  const baseUrl = "/user/save";

  const { email, password, firstName, lastName, name, role, userType } = params;

  var form = new FormData();
  form.append("userType", userType);
  form.append("email", email);
  form.append("firstName", firstName);
  form.append("lastName", lastName);
  form.append("orgName", name);
  form.append("orgRole", role);
  form.append("password", password);

  return api.create(`${baseUrl}`, form);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

function validateCode(params: { email: string; code: string }) {
  const baseUrl = "/user/validateCode";

  const { email, code } = params;

  var form = new FormData();
  form.append("emailCode", code);
  form.append("username", email);

  return api.create(`${baseUrl}`, form);
}

function listKeys() {
  const baseUrl = "/user/listPrimaryKey";

  return api.get(`${baseUrl}`, {});
}

function saveNonCustodianKeys(params: { publicKey: any; address: string }) {
  const baseUrl = "/keySet/saveNonCustodian";

  const { publicKey, address } = params;

  var form = new FormData();
  form.append("publicKey", publicKey);
  form.append("address", address);

  return api.create(`${baseUrl}`, form);
}

function saveTermsAndConditions(params: { terms: any; signature: string }) {
  const baseUrl = "/termsAndConditions/save";

  const { terms, signature } = params;

  var form = new FormData();
  form.append("termsAndConditions", terms);
  form.append("signature", signature);

  return api.create(`${baseUrl}`, form);
}

export {
  login,
  logout,
  signup,
  forgotPassword,
  validateCode,
  listKeys,
  saveNonCustodianKeys,
  saveTermsAndConditions,
};
