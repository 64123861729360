import FeatherIcons from "feather-icons-react";
import { maskingString } from "../helpers/helperMethods";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddressItem = ({ address }: any) => {
  return (
    <>
      {maskingString(address, "small")}

      <FeatherIcons
        icon={"copy"}
        className="icon-dual"
        size={15}
        style={{ marginLeft: "4px", cursor: "pointer" }}
        onClick={(event: any) => {
          event.preventDefault();
          event.stopPropagation();

          navigator.clipboard.writeText(address);

          toast.info("Copied", {
            position: "top-right",
          });
        }}
      />

      <ToastContainer autoClose={1000} theme="dark" hideProgressBar={true} />
    </>
  );
};

export default AddressItem;
