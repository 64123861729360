import { useState } from "react";
import { FormInput } from "../../../components";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  createDataJob,
  createJobCondition,
  createJobExecution,
  createJobTrigger,
} from "../../../redux/actions";
import Loader from "../../../components/Loader";
import {
  APTOS,
  AVALANCHE,
  BNB,
  ETHEREUM,
  POLKADOT,
  POLYGON,
  SOLANA,
} from "./constants";

const CreateDataJob = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    loading,
    error,
    createJobSuccess,
    createJobLoading,
    jobAddress,
    createTriggerSuccess,
    createTriggerLoading,
    createConditionSuccess,
    createConditionLoading,
    createExecutionSuccess,
    createExecutionLoading,
  } = useSelector((state: RootState) => ({
    loading: state.Datalake.loading,
    error: state.Datalake.error,
    createJobSuccess: state.Datalake.createJobSuccess,
    createJobLoading: state.Datalake.createJobLoading,
    jobAddress: state.Datalake.jobAddress,
    createTriggerSuccess: state.Datalake.createTriggerSuccess,
    createTriggerLoading: state.Datalake.createTriggerLoading,
    createConditionSuccess: state.Datalake.createConditionSuccess,
    createConditionLoading: state.Datalake.createConditionLoading,
    createExecutionSuccess: state.Datalake.createExecutionSuccess,
    createExecutionLoading: state.Datalake.createExecutionLoading,
  }));

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [triggerEvent, setTriggerEvent] = useState("");
  const [cronExpression, setCronExpression] = useState("");
  const [apiValue, setApiValue] = useState("");
  const [address, setAddress] = useState("");
  const [eventName, setEventName] = useState("");
  const [condition, setCondition] = useState("");
  const [aiModel, setAiModel] = useState("");
  const [sqlQuery, setSqlQuery] = useState("");
  const [execution, setExecution] = useState("");
  const [execAddress, setExecAddress] = useState("");
  const [method, setMethod] = useState("");
  const [apiUrl, setApiUrl] = useState("");
  const [dataParams, setDataParams] = useState("");
  const [params, setParams] = useState("");
  const [blockchainList, setBlockchainList] = useState("");

  const onValueChange = (event: any, type: string) => {
    if (type === "name") {
      setName(event.target.value);
    } else if (type === "description") {
      setDescription(event.target.value);
    } else if (type === "trigger") {
      setTriggerEvent(event.target.value);
    } else if (type === "cron") {
      setCronExpression(event.target.value);
    } else if (type === "apiValue") {
      setApiValue(event.target.value);
    } else if (type === "address") {
      setAddress(event.target.value);
    } else if (type === "eventName") {
      setEventName(event.target.value);
    } else if (type === "condition") {
      setCondition(event.target.value);
    } else if (type === "query") {
      setSqlQuery(event.target.value);
    } else if (type === "execution") {
      setExecution(event.target.value);
    } else if (type === "execAddress") {
      setExecAddress(event.target.value);
    } else if (type === "method") {
      setMethod(event.target.value);
    } else if (type === "apiUrl") {
      setApiUrl(event.target.value);
    } else if (type === "dataParams") {
      setDataParams(event.target.value);
    } else if (type === "params") {
      setParams(event.target.value);
    }
  };

  const onAiModelSelect = (event: any) => {
    setAiModel(event.target.value);
  };

  const onSaveJob = () => {
    dispatch(createDataJob(name, description, state));
  };

  const onSaveJobTrigger = () => {
    let onNewData = false;
    if (triggerEvent === "ON_NEW_DATA") {
      onNewData = true;
    }

    let cronExp = triggerEvent === "CRON" ? cronExpression : null;
    let smartContractAddress =
      triggerEvent === "SMART_CONTRACT_EVENT" ? address : null;
    let functionName =
      triggerEvent === "SMART_CONTRACT_EVENT" ? eventName : null;

    let blockchainNetwork =
      triggerEvent === "SMART_CONTRACT_EVENT" ? blockchainList : null;
    let smartContractParameters =
      triggerEvent === "SMART_CONTRACT_EVENT" ? params : null;

    dispatch(
      createJobTrigger(
        jobAddress,
        triggerEvent,
        cronExp,
        onNewData,
        smartContractAddress,
        functionName,
        blockchainNetwork,
        smartContractParameters
      )
    );
  };

  const onSaveJobCondition = () => {
    let sqlQueryValue = condition === "sql" ? sqlQuery : null;
    let aiModelValue = condition === "ai" ? aiModel : null;

    dispatch(createJobCondition(jobAddress, sqlQueryValue, aiModelValue));
  };

  const onSaveJobExecution = () => {
    let smartContractAddress = execution === "sendToSC" ? execAddress : null;
    let functionName = execution === "sendToSC" ? method : null;
    let apiURL = execution === "sendToAPI" ? apiUrl : null;
    let paramList = execution === "sendToSC" ? dataParams : null;
    let outcomeType =
      execution === "sendToSC"
        ? "SMART_CONTRACT"
        : execution === "sendToAPI"
        ? "EXTERNAL_API"
        : null;

    dispatch(
      createJobExecution(
        jobAddress,
        smartContractAddress,
        functionName,
        apiURL,
        paramList,
        outcomeType
      )
    );
  };

  const onBlockchainSelect = (event: any) => {
    setBlockchainList(event.target.value);
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <div>
      {createExecutionSuccess && onCancel()}

      {(createJobLoading ||
        loading ||
        createTriggerLoading ||
        createConditionLoading ||
        createExecutionLoading) && <Loader />}

      <h4 className="mt-3 mb-3">Create a new Job</h4>

      <FormInput
        type="text"
        label="Job Name"
        name="name"
        className="form-control"
        placeholder="Enter Name"
        containerClass={"mb-3"}
        key="name"
        onChange={(event) => {
          onValueChange(event, "name");
        }}
        value={name}
        disabled={createJobSuccess}
      />

      <FormInput
        type="textarea"
        label="Description"
        name="description"
        className="form-control"
        placeholder="Enter Description"
        containerClass={"mb-3"}
        key="description"
        onChange={(event) => {
          onValueChange(event, "description");
        }}
        value={description}
        rows={3}
        disabled={createJobSuccess}
      />

      {!createJobSuccess && (
        <div>
          <Button
            style={{ float: "right" }}
            className="btn-cta"
            onClick={onSaveJob}
          >
            Save Job
          </Button>
        </div>
      )}

      {createJobSuccess && (
        <>
          <h5 className="mt-3 mb-1">Trigger</h5>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <p>When does this Job start?</p>
                  <FormInput
                    type="radio"
                    label="At a specific time/date"
                    name="trigger"
                    key="trigger"
                    onChange={(event) => {
                      onValueChange(event, "trigger");
                    }}
                    value="CRON"
                    disabled={createTriggerSuccess}
                  />

                  <FormInput
                    type="radio"
                    label="When an API is called"
                    name="trigger"
                    key="trigger"
                    onChange={(event) => {
                      onValueChange(event, "trigger");
                    }}
                    value="API_EVENT"
                    disabled={createTriggerSuccess}
                  />

                  <FormInput
                    type="radio"
                    label="When a smart contract event is emitted"
                    name="trigger"
                    key="trigger"
                    onChange={(event) => {
                      onValueChange(event, "trigger");
                    }}
                    value="SMART_CONTRACT_EVENT"
                    disabled={createTriggerSuccess}
                  />

                  <FormInput
                    type="radio"
                    label="On New Data"
                    name="trigger"
                    key="trigger"
                    onChange={(event) => {
                      onValueChange(event, "trigger");
                    }}
                    value="ON_NEW_DATA"
                    disabled={createTriggerSuccess}
                  />
                </Col>

                <Col lg={6}>
                  {triggerEvent === "CRON" && (
                    <FormInput
                      type="text"
                      label="CRON Expression"
                      name="cron"
                      className="form-control"
                      placeholder="Pick a date-time pattern"
                      containerClass={"mb-3"}
                      key="cron"
                      onChange={(event) => {
                        onValueChange(event, "cron");
                      }}
                      value={cronExpression}
                      disabled={createTriggerSuccess}
                    />
                  )}

                  {triggerEvent === "API_EVENT" && (
                    <h6>
                      An API endpoint will be generated once the trigger is
                      saved.
                    </h6>
                  )}

                  {triggerEvent === "SMART_CONTRACT_EVENT" && (
                    <>
                      <div className="mb-2">
                        <Form.Select
                          name="blockchainList"
                          onChange={(event) => {
                            onBlockchainSelect(event);
                          }}
                          key="blockchainList"
                          style={{ marginBottom: "1rem" }}
                          value={blockchainList}
                          disabled={createConditionSuccess}
                        >
                          <option>Select Blockchain Network</option>
                          <option value={ETHEREUM}>Ethereum</option>
                          <option value={POLYGON}>Polygon</option>
                          <option value={SOLANA}>Solana</option>
                          <option value={APTOS}>Aptos</option>
                          <option value={BNB}>BNB</option>
                          <option value={AVALANCHE}>Avalanche</option>
                          <option value={POLKADOT}>Polkadot</option>
                        </Form.Select>
                      </div>

                      <FormInput
                        type="text"
                        label="Address"
                        name="address"
                        className="form-control"
                        placeholder="Address"
                        containerClass={"mb-2"}
                        key="address"
                        onChange={(event) => {
                          onValueChange(event, "address");
                        }}
                        value={address}
                        disabled={createTriggerSuccess}
                      />

                      <FormInput
                        type="text"
                        label="Event Name"
                        name="eventName"
                        className="form-control"
                        placeholder="Event"
                        containerClass={"mb-2"}
                        key="address"
                        onChange={(event) => {
                          onValueChange(event, "eventName");
                        }}
                        value={eventName}
                        disabled={createTriggerSuccess}
                      />

                      <FormInput
                        type="text"
                        label="Parameters"
                        name="params"
                        className="form-control"
                        placeholder="Event"
                        containerClass={"mb-2"}
                        key="params"
                        onChange={(event) => {
                          onValueChange(event, "params");
                        }}
                        value={params}
                        disabled={createTriggerSuccess}
                      />
                    </>
                  )}
                </Col>
              </Row>

              {!createTriggerSuccess && (
                <div>
                  <Button
                    style={{ margin: "8px 0", float: "right" }}
                    onClick={onSaveJobTrigger}
                    className="btn-cta"
                  >
                    Save Trigger
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </>
      )}

      {createTriggerSuccess && (
        <>
          <h5 className="mt-3 mb-1">Condition</h5>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <p>What needs to be executed?</p>

                  <FormInput
                    type="radio"
                    label="Execute SQL Query"
                    name="condition"
                    key="condition"
                    onChange={(event) => {
                      onValueChange(event, "condition");
                    }}
                    value="sql"
                    disabled={createConditionSuccess}
                  />

                  <FormInput
                    type="radio"
                    label="Execute AI/ML model on data"
                    name="condition"
                    key="condition"
                    onChange={(event) => {
                      onValueChange(event, "condition");
                    }}
                    value="ai"
                    disabled={createConditionSuccess}
                  />
                </Col>

                <Col lg={6}>
                  {condition === "sql" && (
                    <FormInput
                      type="textarea"
                      label="SQL Query"
                      name="query"
                      className="form-control"
                      placeholder="Enter SQL Query"
                      containerClass={"mb-3"}
                      key="query"
                      onChange={(event) => {
                        onValueChange(event, "query");
                      }}
                      value={sqlQuery}
                      rows={3}
                      disabled={createConditionSuccess}
                    />
                  )}

                  {condition === "ai" && (
                    <>
                      <Form.Select
                        name="aiModel"
                        onChange={(event) => {
                          onAiModelSelect(event);
                        }}
                        key="aiModel"
                        style={{ marginBottom: "1rem" }}
                        value={aiModel}
                        disabled={createConditionSuccess}
                      >
                        <option>Select AI/ML Model</option>
                        <option value="FACE_RECOGNITION">
                          Face Recognition
                        </option>
                        <option value="ANOMALY_DETECTION">
                          Anomaly Detection
                        </option>
                        <option value="FEDERATED_PRICE_MATCHING">
                          Federated Price Matching
                        </option>
                      </Form.Select>
                    </>
                  )}
                </Col>
              </Row>

              {!createConditionSuccess && (
                <div>
                  <Button
                    style={{ margin: "8px 0", float: "right" }}
                    onClick={onSaveJobCondition}
                  >
                    Save Condition
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </>
      )}

      {createConditionSuccess && (
        <>
          <h5 className="mt-3 mb-1">Execution</h5>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <p>What to do with result?</p>

                  <FormInput
                    type="radio"
                    label="Send to Smart Contract"
                    name="execution"
                    key="execution"
                    onChange={(event) => {
                      onValueChange(event, "execution");
                    }}
                    value="sendToSC"
                  />

                  <FormInput
                    type="radio"
                    label="Send to API"
                    name="execution"
                    key="execution"
                    onChange={(event) => {
                      onValueChange(event, "execution");
                    }}
                    value="sendToAPI"
                  />
                </Col>

                <Col lg={6}>
                  {execution === "sendToSC" && (
                    <>
                      <FormInput
                        type="text"
                        label="Address"
                        name="execAddress"
                        className="form-control"
                        placeholder="Address"
                        containerClass={"mb-3"}
                        key="execAddress"
                        onChange={(event) => {
                          onValueChange(event, "execAddress");
                        }}
                        value={execAddress}
                      />

                      <FormInput
                        type="text"
                        label="Method Name"
                        name="method"
                        className="form-control"
                        placeholder="Enter Method name"
                        containerClass={"mb-3"}
                        key="method"
                        onChange={(event) => {
                          onValueChange(event, "method");
                        }}
                        value={method}
                      />

                      <FormInput
                        type="text"
                        label="Parameters"
                        name="data"
                        className="form-control"
                        placeholder="Enter parameters"
                        containerClass={"mb-3"}
                        key="data"
                        onChange={(event) => {
                          onValueChange(event, "dataParams");
                        }}
                        value={dataParams}
                      />
                    </>
                  )}

                  {execution === "sendToAPI" && (
                    <FormInput
                      type="text"
                      label="API URL"
                      name="apiUrl"
                      className="form-control"
                      placeholder="Enter Method name"
                      containerClass={"mb-3"}
                      key="apiUrl"
                      onChange={(event) => {
                        onValueChange(event, "apiUrl");
                      }}
                      value={apiUrl}
                    />
                  )}
                </Col>
              </Row>

              {!createExecutionSuccess && (
                <div>
                  <Button
                    style={{ margin: "8px 0", float: "right" }}
                    onClick={onSaveJobExecution}
                    className="btn-cta"
                  >
                    Save Execution
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </div>
  );
};

export default CreateDataJob;
