import {
  APTOS,
  AVALANCHE,
  BNB,
  CARDANO,
  ETHEREUM,
  POLKADOT,
  POLYGON,
  SOLANA,
} from "../pages/dashboard/DataLakes/constants";

import ethereumIcon from "../assets/images/brands/ethereum.svg";
import aptosIcon from "../assets/images/brands/aptos.svg";
import polygonIcon from "../assets/images/brands/polygon.svg";
import solanaIcon from "../assets/images/brands/solana.svg";
import bnbIcon from "../assets/images/brands/bnb.svg";
import avalancheIcon from "../assets/images/brands/avalanche.svg";
import polkadotIcon from "../assets/images/brands/polkadot.svg";
import cardanoIcon from "../assets/images/brands/cardano.svg";

export const getDate = (keyDate: any) => {
  let dateCreated = new Date(keyDate);

  let year = dateCreated.getFullYear();
  let month = dateCreated.getMonth() + 1;
  let day = dateCreated.getDate();
  let hours = dateCreated.getHours();
  let minutes = dateCreated.getMinutes();
  let seconds = dateCreated.getSeconds();

  let time = hours + ":" + minutes + ":" + seconds;

  return day + " / " + month + " / " + year + " " + time;
};

export const maskingString = (address: any, type: any) => {
  const maskLength = 5;

  const maskedStr =
    address.substring(0, 5) +
    "*".repeat(maskLength) +
    address.substring(address.length);

  return maskedStr;
};

export const getHash = (text: string) => {
  return text.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export const getActualStakeAmount = (amount: any) => {
  let amountValue = parseInt(amount);

  return amountValue / Math.pow(10, 18) + " TTKAN";
};

export const getIcon = (blockchainName: string) => {
  if (blockchainName === ETHEREUM) {
    return ethereumIcon;
  }
  if (blockchainName === POLYGON) {
    return polygonIcon;
  }
  if (blockchainName === SOLANA) {
    return solanaIcon;
  }
  if (blockchainName === APTOS) {
    return aptosIcon;
  }
  if (blockchainName === CARDANO) {
    return cardanoIcon;
  }
  if (blockchainName === BNB) {
    return bnbIcon;
  }
  if (blockchainName === POLKADOT) {
    return polkadotIcon;
  }
  if (blockchainName === AVALANCHE) {
    return avalancheIcon;
  }
};
