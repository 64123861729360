import React from "react";
import { Row, Col } from "react-bootstrap";

import classNames from "classnames";

import { getDate } from "../helpers/helperMethods";

interface StatisticsChartWidgetProps {
  colors?: Array<string>;
  containerClass?: string;
  type?:
    | "line"
    | "area"
    | "bar"
    | "histogram"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "treemap"
    | "boxPlot"
    | "candlestick"
    | "radar"
    | "polarArea"
    | "rangeBar";
  name?: string;
  data?: Array<number>;
  title?: string;
  stats?: string;
  trend?: boolean;
}

const StatisticsChartWidget2 = (props: StatisticsChartWidgetProps) => {
  return (
    <Row className={props.containerClass}>
      <Col md={12} xs={12}>
        <h6 className="my-2">Public Key - {props.title}</h6>
        <h6 className="my-2">Created On - {getDate(props.stats)}</h6>
        <h6 className={classNames("mb-0 text-primary")}>
          Provider - {props.trend === true ? "Kandola" : "MetaMask"}
        </h6>
      </Col>
    </Row>
  );
};

export default StatisticsChartWidget2;
