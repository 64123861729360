import React from "react";
import { Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import CreateNewProposal from "../pages/dashboard/Dashboard/CreateNewProposal";
import ListKeys from "../pages/dashboard/Dashboard/Keys/ListKeys";
import CreateKeys from "../pages/dashboard/Dashboard/Keys/CreateKeys";
import SelectRole from "../pages/auth/SelectRole";
import Terms from "../pages/auth/Terms";
import Stakes from "../pages/dashboard/StakingProfile/Stakes";
import Nodes from "../pages/dashboard/StakingProfile/Nodes";
import Bids from "../pages/dashboard/Dashboard/Bids/Bids";
import ViewDeploymentDetails from "../pages/dashboard/Dashboard/Bids/ViewDeploymentDetails";
import MyDeployments from "../pages/dashboard/Dashboard/Bids/MyDeployments";
import DataLakes from "../pages/dashboard/DataLakes/DataLakes";
import CreateDataLake from "../pages/dashboard/DataLakes/CreateDataLake";
import ViewDataLake from "../pages/dashboard/DataLakes/ViewDataLake";
import CreateDataJob from "../pages/dashboard/DataLakes/CreateDataJob";
import ViewDataJob from "../pages/dashboard/DataLakes/ViewDataJob";
// import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// landing
const Landing = React.lazy(() => import("../pages/landing/"));

// dashboard
// const EcommerceDashboard = React.lazy(
//   () => import("../pages/dashboard/Ecommerce/")
// );
const AnalyticsDashboard = React.lazy(
  () => import("../pages/dashboard/Dashboard/")
);

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  element: <AnalyticsDashboard />,
  children: [
    {
      path: "/create-proposal",
      name: "CreateProposal",
      element: <CreateNewProposal />,
      route: PrivateRoute,
    },
    {
      path: "/keys",
      name: "UserKeys",
      element: <ListKeys />,
      route: PrivateRoute,
    },
    {
      path: "/create-keys",
      name: "UserKeys",
      element: <CreateKeys />,
      route: PrivateRoute,
    },
    {
      path: "/create-keys/secondary",
      name: "UserKeys",
      element: <CreateKeys />,
      route: PrivateRoute,
    },
    {
      path: "/terms",
      name: "Terms and Conditions",
      element: <Terms />,
      route: PrivateRoute,
    },
    {
      path: "/staking-profile",
      name: "Staking Profile",
      element: <Stakes />,
      route: PrivateRoute,
    },
    {
      path: "/infra-nodes",
      name: "Nodes",
      element: <Nodes />,
      route: PrivateRoute,
    },
    {
      path: "/bids",
      name: "Bids",
      element: <Bids />,
      route: PrivateRoute,
    },
    {
      path: "/deployment-details/:id",
      name: "Deployment",
      element: <ViewDeploymentDetails />,
      route: PrivateRoute,
    },
    {
      path: "/deployments",
      name: "My Deployments",
      element: <MyDeployments />,
      route: PrivateRoute,
    },
    {
      path: "/data-lakes",
      name: "Data Lakes",
      element: <DataLakes />,
      route: PrivateRoute,
    },
    {
      path: "/create-data-lake",
      name: "Create DataLakes",
      element: <CreateDataLake />,
      route: PrivateRoute,
    },
    {
      path: "/datalake-details",
      name: "View DataLakes",
      element: <ViewDataLake />,
      route: PrivateRoute,
    },
    {
      path: "/create-data-job",
      name: "Data Job",
      element: <CreateDataJob />,
      route: PrivateRoute,
    },
    {
      path: "/view-data-job",
      name: "Data Job Details",
      element: <ViewDataJob />,
      route: PrivateRoute,
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/register-provider",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
  {
    path: "/auth/select-role",
    name: "Select Role",
    element: <SelectRole />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/landing",
    name: "landing",
    element: <Landing />,
    route: Route,
    children: [
      {
        path: "/",
        name: "Root",
        element: <Landing />,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [dashboardRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
