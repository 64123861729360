import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  getDataLakes as getDataLakesApi,
  createDataLake as createDataLakeApi,
  getDataLakeDetails as getDataLakeDetailsApi,
  createDataJob as createDataJobApi,
  createDataJobCondition as createDataJobConditionApi,
  createDataJobExecution as createDataJobExecutionApi,
  createDataJobTrigger as createDataJobTriggerApi,
  getDataJobsDetails as getDataJobsDetailsApi,
} from "../../helpers/";

// actions
import {
  datalakeApiResponseError,
  datalakeApiResponseSuccess,
} from "./actions";

// constants
import { DataLakeActionTypes } from "./constants";

interface DatalakeData {
  payload: {
    name: string;
    blockchainList: any;
    fileList: any;
    deploymentList: any;
    s3url: any;
    url: any;
    description: any;
    triggerType: any;
    cronExpression: any;
    onNewData: any;
    internalApiURL: any;
    smartContractAddress: any;
    functionName: any;
    blockchainNetwork: any;
    smartContractParameters: any;
    sqlQuery: any;
    aiModel: any;
    apiURL: any;
    paramList: any;
    outcomeType: any;
    address: any;
  };
  type: string;
}

/**
 * Get list of saved data lakes
 */
function* getDataLakes(): SagaIterator {
  try {
    const response = yield call(getDataLakesApi);

    yield put(
      datalakeApiResponseSuccess(
        DataLakeActionTypes.GET_DATALAKES,
        response.data.message.datalakes
      )
    );
  } catch (error: any) {
    yield put(
      datalakeApiResponseError(DataLakeActionTypes.GET_DATALAKES, error)
    );
  }
}

/**
 * Create Data Lake
 */
function* createDataLake({
  payload: { name, url, blockchainList, fileList, deploymentList, s3url },
}: DatalakeData): SagaIterator {
  try {
    const response = yield call(createDataLakeApi, {
      name,
      url,
      blockchainList,
      fileList,
      deploymentList,
      s3url,
    });
    yield put(
      datalakeApiResponseSuccess(
        DataLakeActionTypes.CREATE_DATALAKE,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      datalakeApiResponseError(DataLakeActionTypes.CREATE_DATALAKE, error)
    );
  }
}

/**
 * Get Data Lake Details
 */
function* getDataLakeDetails(): SagaIterator {
  try {
    const response = yield call(getDataLakeDetailsApi);
    yield put(
      datalakeApiResponseSuccess(
        DataLakeActionTypes.VIEW_DATALAKE_DETAILS,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      datalakeApiResponseError(DataLakeActionTypes.VIEW_DATALAKE_DETAILS, error)
    );
  }
}

/**
 * Get Data Jobs
 */
function* getDataJobsDetails({
  payload: { address },
}: DatalakeData): SagaIterator {
  try {
    const response = yield call(getDataJobsDetailsApi, {
      address,
    });
    yield put(
      datalakeApiResponseSuccess(
        DataLakeActionTypes.GET_DATA_JOBS,
        response.data.message.jobs
      )
    );
  } catch (error: any) {
    yield put(
      datalakeApiResponseError(DataLakeActionTypes.GET_DATA_JOBS, error)
    );
  }
}

/**
 * Create Data Job
 */
function* createDataJob({
  payload: { name, description, address },
}: DatalakeData): SagaIterator {
  try {
    const response = yield call(createDataJobApi, {
      name,
      description,
      address,
    });
    yield put(
      datalakeApiResponseSuccess(
        DataLakeActionTypes.CREATE_DATA_JOB,
        response.data.message.job
      )
    );
  } catch (error: any) {
    yield put(
      datalakeApiResponseError(DataLakeActionTypes.CREATE_DATA_JOB, error)
    );
  }
}

/**
 * Create Data Job Trigger
 */
function* createDataJobTrigger({
  payload: {
    address,
    triggerType,
    cronExpression,
    onNewData,
    smartContractAddress,
    functionName,
    blockchainNetwork,
    smartContractParameters,
  },
}: DatalakeData): SagaIterator {
  try {
    const response = yield call(createDataJobTriggerApi, {
      address,
      triggerType,
      cronExpression,
      onNewData,
      smartContractAddress,
      functionName,
      blockchainNetwork,
      smartContractParameters,
    });
    yield put(
      datalakeApiResponseSuccess(
        DataLakeActionTypes.CREATE_JOB_TRIGGER,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      datalakeApiResponseError(DataLakeActionTypes.CREATE_JOB_TRIGGER, error)
    );
  }
}

/**
 * Create Data Job Condition
 */
function* createDataJobCondition({
  payload: { address, sqlQuery, aiModel },
}: DatalakeData): SagaIterator {
  try {
    const response = yield call(createDataJobConditionApi, {
      address,
      sqlQuery,
      aiModel,
    });
    yield put(
      datalakeApiResponseSuccess(
        DataLakeActionTypes.CREATE_JOB_CONDITION,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      datalakeApiResponseError(DataLakeActionTypes.CREATE_JOB_CONDITION, error)
    );
  }
}

/**
 * Create Data Job Execution
 */
function* createDataJobExecution({
  payload: {
    address,
    smartContractAddress,
    functionName,
    apiURL,
    paramList,
    outcomeType,
  },
}: DatalakeData): SagaIterator {
  try {
    const response = yield call(createDataJobExecutionApi, {
      address,
      smartContractAddress,
      functionName,
      apiURL,
      paramList,
      outcomeType,
    });
    yield put(
      datalakeApiResponseSuccess(
        DataLakeActionTypes.CREATE_JOB_EXECUTION,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      datalakeApiResponseError(DataLakeActionTypes.CREATE_JOB_EXECUTION, error)
    );
  }
}

export function* watchGetDataLakes(): any {
  yield takeEvery(DataLakeActionTypes.GET_DATALAKES, getDataLakes);
}

export function* watchGetDataLakeDetails(): any {
  yield takeEvery(
    DataLakeActionTypes.VIEW_DATALAKE_DETAILS,
    getDataLakeDetails
  );
}

export function* watchCreateDataLake(): any {
  yield takeEvery(DataLakeActionTypes.CREATE_DATALAKE, createDataLake);
}

export function* watchCreateDataJob(): any {
  yield takeEvery(DataLakeActionTypes.CREATE_DATA_JOB, createDataJob);
}

export function* watchCreateDataJobTrigger(): any {
  yield takeEvery(DataLakeActionTypes.CREATE_JOB_TRIGGER, createDataJobTrigger);
}

export function* watchCreateDataJobCondition(): any {
  yield takeEvery(
    DataLakeActionTypes.CREATE_JOB_CONDITION,
    createDataJobCondition
  );
}

export function* watchCreateDataJobExecution(): any {
  yield takeEvery(
    DataLakeActionTypes.CREATE_JOB_EXECUTION,
    createDataJobExecution
  );
}

export function* watchGetDataJobs(): any {
  yield takeEvery(DataLakeActionTypes.GET_DATA_JOBS, getDataJobsDetails);
}

function* datalakeSaga() {
  yield all([
    fork(watchGetDataLakes),
    fork(watchGetDataLakeDetails),
    fork(watchCreateDataLake),
    fork(watchCreateDataJob),
    fork(watchCreateDataJobTrigger),
    fork(watchCreateDataJobCondition),
    fork(watchCreateDataJobExecution),
    fork(watchGetDataJobs),
  ]);
}

export default datalakeSaga;
