import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Hero from "../landing/Hero";

import img2 from "../../assets/images/layouts/storage-node.png";
import NavBar from "../landing/NavBar";

const SelectRole = () => {
  const navigate = useNavigate();

  const onStorageNodeClick = () => {
    navigate("/auth/register-provider");
  };

  return (
    <>
      <NavBar />
      <Hero />

      <section className="py-2 bg-body border-top">
        <h3 className="text-center mb-3">Select Your Role</h3>

        <Container>
          <Row>
            <Col md={4}>
              <Card className="mb-2 home-page-node-card">
                <Card.Body
                  className="pt-1 pb-1 pl-2 pr-2"
                  style={{ minHeight: "300px" }}
                >
                  <Card.Title className="fs-20 mb-3 mt-2 text-center">
                    Validator Node
                  </Card.Title>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={img2} alt="" style={{ width: "20%" }} />
                  </div>

                  <Card.Text className="mt-2 mb-1 text-center fs-14">
                    Validator nodes are also called Log Nodes in our network.
                    Participate as a validator node in the network and get
                    tokens for creating blocks and verifying transactions in the
                    network. be resposnible for keeping the data transaction
                    audits in the Kandola Blockchain.
                  </Card.Text>
                  <hr style={{ margin: "0.8rem 0" }} />

                  <Card.Text
                    className="text-center mb-2"
                    style={{ color: "#D84C2C" }}
                  >
                    Coming in TestNet Phase 2
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className="mb-2 home-page-node-card">
                <Card.Body
                  className="pt-1 pb-1 pl-2 pr-2"
                  style={{ minHeight: "300px" }}
                  //   onClick={onNodeProviderClick}
                >
                  <Card.Title className="fs-20 mb-3 mt-2 text-center">
                    Audit Node
                  </Card.Title>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={img2} alt="" style={{ width: "20%" }} />
                  </div>

                  <Card.Text className="mt-2 mb-1 text-center fs-14">
                    Audit nodes are the policeman in our network. Participate as
                    a audit node in the network and get tokens for creating
                    challenges and auditing the storage nodes. Be resposnible
                    for ensuring all strorage nodes are working correctly. Audit
                    nodes also receive slash rewards for the nodes they report
                    are Byzantine.
                  </Card.Text>
                  <hr style={{ margin: "0.8rem 0" }} />

                  <Card.Text
                    className="text-center mb-2"
                    style={{ color: "#D84C2C" }}
                  >
                    Coming in TestNet Phase 3
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card
                className="animate mb-2 home-page-node-card"
                style={{ cursor: "pointer" }}
              >
                <Card.Body
                  className="pt-1 pb-1 pl-2 pr-2"
                  style={{ minHeight: "300px" }}
                  onClick={onStorageNodeClick}
                >
                  <Card.Title className="fs-20 mt-2 mb-3 text-center">
                    Storage Node
                  </Card.Title>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={img2} alt="" style={{ width: "20%" }} />
                  </div>

                  <Card.Text className="mt-2 mb-1 text-center fs-14">
                    Storage nodes are the service providers in our network.
                    Participate as a storage node in the network and get tokens
                    for providing database as a service. Storage nodes also get
                    rewarded for every audit they pass successfully. Bid for
                    proposals and generate fixed and steady revenue.
                  </Card.Text>
                  <hr style={{ margin: "0.8rem 0" }} />

                  <Card.Text
                    className="text-center mb-2"
                    style={{ color: "#D84C2C" }}
                  >
                    <b>Live Now!</b>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SelectRole;
