import { APICore } from "./apiCore";

const api = new APICore();

// get Data Lakes
function getDataLakes() {
  const baseUrl = "/dataLake/fetchMyDataLakes";
  return api.get(`${baseUrl}`, {});
}

//create data lake
function createDataLake(params: {
  name: string;
  blockchainList: any;
  fileList: any;
  deploymentList: any;
  s3url: any;
  url: any;
}) {
  const baseUrl = "/dataLake/save";

  const { name, blockchainList, url, fileList, deploymentList, s3url } = params;

  var form = new FormData();
  form.append("url", url);
  form.append("name", name);
  form.append("s3url", s3url);
  form.append("blockchainList", blockchainList);
  form.append("deploymentList", deploymentList);
  form.append("fileList", fileList);

  return api.create(`${baseUrl}`, form);
}

// get data lake details
function getDataLakeDetails() {
  const baseUrl = "/stakeTransaction/fetchStakeDetails";
  return api.get(`${baseUrl}`, {});
}

// get data jobs
function getDataJobsDetails(params: { address: any }) {
  const baseUrl = "/job/fetchJobsForDataLake?dataLakeAddress=" + params.address;
  return api.get(`${baseUrl}`, {});
}

//create data job
function createDataJob(params: {
  name: string;
  description: any;
  address: any;
}) {
  const baseUrl = "/job/createJob";

  const { name, description, address } = params;

  var form = new FormData();
  form.append("description", description);
  form.append("name", name);
  form.append("address", address);

  return api.create(`${baseUrl}`, form);
}

//create data job trigger
function createDataJobTrigger(params: {
  address: string;
  triggerType: any;
  cronExpression: any;
  onNewData: any;
  smartContractAddress: any;
  functionName: any;
  blockchainNetwork: any;
  smartContractParameters: any;
}) {
  const baseUrl = "/job/createJobTrigger";

  const {
    address,
    triggerType,
    cronExpression,
    onNewData,
    smartContractAddress,
    functionName,
    blockchainNetwork,
    smartContractParameters,
  } = params;

  var form = new FormData();
  form.append("address", address);
  form.append("triggerType", triggerType);

  if (triggerType === "CRON") {
    form.append("cronExpression", cronExpression);
  } else if (triggerType === "API_EVENT") {
  } else if (triggerType === "SMART_CONTRACT_EVENT") {
    form.append("smartContractAddress", smartContractAddress);
    form.append("functionName", functionName);
    form.append("blockchainNetwork", blockchainNetwork);
    form.append("smartContractParameters", smartContractParameters);
  } else if (triggerType === "ON_NEW_DATA") {
    form.append("onNewData", onNewData);
  }

  return api.create(`${baseUrl}`, form);
}

//create data job condition
function createDataJobCondition(params: {
  address: string;
  sqlQuery: any;
  aiModel: any;
}) {
  const baseUrl = "/job/createJobCondition";

  const { address, sqlQuery, aiModel } = params;

  var form = new FormData();
  form.append("address", address);

  if (sqlQuery) {
    form.append("sqlQuery", sqlQuery);
  }
  if (aiModel) {
    form.append("aiModel", aiModel);
  }

  return api.create(`${baseUrl}`, form);
}

//create data job execution
function createDataJobExecution(params: {
  address: string;
  smartContractAddress: any;
  functionName: any;
  apiURL: any;
  paramList: any;
  outcomeType: any;
}) {
  const baseUrl = "/job/createJobExecutionDetails";

  const {
    address,
    smartContractAddress,
    functionName,
    apiURL,
    paramList,
    outcomeType,
  } = params;

  var form = new FormData();
  form.append("address", address);
  form.append("outcomeType", outcomeType);

  if (outcomeType === "SMART_CONTRACT") {
    form.append("smartContractAddress", smartContractAddress);
    form.append("functionName", functionName);
    form.append("paramList", paramList);
  }
  if (outcomeType === "EXTERNAL_API") {
    form.append("apiURL", apiURL);
  }

  return api.create(`${baseUrl}`, form);
}

export {
  getDataLakes,
  createDataLake,
  getDataLakeDetails,
  createDataJob,
  createDataJobCondition,
  createDataJobExecution,
  createDataJobTrigger,
  getDataJobsDetails,
};
