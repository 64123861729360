import { Button, Card, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AddressItem from "../../../components/AddressItem";

import eth from "../../../assets/images/icons/eth.jpg";
import { getDataJobsDetails } from "../../../redux/actions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";

import Loader from "../../../components/Loader";
import { getIcon } from "../../../helpers/helperMethods";

const ViewDataLake = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const { loading, error, dataJobs, getDataJobsSuccess, dataJobsLoading } =
    useSelector((state: RootState) => ({
      loading: state.Datalake.loading,
      error: state.Datalake.error,
      dataJobs: state.Datalake.dataJobs,
      getDataJobsSuccess: state.Datalake.getDataJobsSuccess,
      dataJobsLoading: state.Datalake.dataJobsLoading,
    }));

  let datalake = JSON.parse(state);

  const onCreateJob = () => {
    navigate("/create-data-job", {
      state: datalake.address,
    });
  };

  const onJobView = (job: any) => {
    navigate("/view-data-job", {
      state: job,
    });
  };

  useEffect(() => {
    dispatch(getDataJobsDetails(datalake.address));
  }, [dispatch, datalake.address]);

  return (
    <section className="py-3 bg-body border-top">
      {(loading || dataJobsLoading) && <Loader />}

      <Card>
        <Card.Body
          style={{
            display: "flex",
            alignItems: "baseline",
            cursor: "pointer",
          }}
        >
          {/* <div>
            <h4>Name : {datalake.name}</h4>
            <h4>
              Address : <AddressItem address={datalake.address || ""} />
            </h4>
          </div> */}

          <div className="proposal-review-item mt-1 mb-2">
            <div className="proposal-review-text">
              <div className="proposal-review-label data-job-label">Name</div>
              <div className="proposal-review-value">{datalake.name}</div>
            </div>
          </div>

          <div className="proposal-review-item mt-1 mb-2">
            <div className="proposal-review-text">
              <div className="proposal-review-label data-job-label">
                Address
              </div>

              <div className="proposal-review-value">
                <AddressItem address={datalake.address || ""} />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <h4>Data Sources</h4>

      <Card className="py-2 px-3">
        <div>
          {datalake && datalake?.deploymentList.length !== 0 && (
            <>
              <h5>Deployments</h5>

              <div className="table-responsive mt-3">
                <table className="table table-sm table-centered mb-0 fs-13">
                  <thead className="table-light">
                    <tr>
                      <th>Name</th>
                      <th>Address</th>
                    </tr>
                  </thead>

                  <tbody>
                    {datalake.deploymentList.map(
                      (deployment: any, index: number) => {
                        return (
                          <tr key={index} style={{ cursor: "pointer" }}>
                            <td>{deployment.name}</td>
                            <td>
                              <AddressItem address={deployment.address} />
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>

        <Row>
          {datalake.blockChainList && datalake.blockChainList.length !== 0 && (
            <Col lg={6}>
              <h5 className="mt-3">Blockchain Data</h5>
              <Row>
                {datalake.blockChainList.length !== 0 &&
                  datalake.blockChainList.map((blockchain: any) => (
                    <Col lg={4} md={4}>
                      <Card className="pt-1">
                        <div className="blockchain-card">
                          <img
                            src={getIcon(blockchain.name)}
                            alt="eth"
                            width="30%"
                          />
                          <h5>{blockchain.name}</h5>
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col>
          )}

          {datalake.fileList && datalake.fileList.length !== 0 && (
            <Col lg={6}>
              <h5 className="mt-3">Files</h5>
              {datalake.fileList.map((file: any) => (
                <p>{file}</p>
              ))}
            </Col>
          )}
        </Row>

        <div style={{ display: "flex" }}>
          {/* {datalake.url && <h4>External API : {datalake.url}</h4>} */}

          {datalake.url && (
            <div className="proposal-review-item mt-1 mb-2">
              <div className="proposal-review-text">
                <div className="proposal-review-label data-job-label">
                  External API
                </div>
                <div className="proposal-review-value">{datalake.url}</div>
              </div>
            </div>
          )}

          {/* {datalake.s3url && <h4>S3 Bucket : {datalake.s3url}</h4>} */}

          {datalake.s3url && (
            <div className="proposal-review-item mt-1 mb-2">
              <div className="proposal-review-text">
                <div className="proposal-review-label data-job-label">
                  S3 Bucket
                </div>
                <div className="proposal-review-value">{datalake.s3url}</div>
              </div>
            </div>
          )}
        </div>
      </Card>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4>Data Jobs</h4>

        <Button onClick={onCreateJob} className="btn-cta">
          Create New Data Job
        </Button>
      </div>

      {getDataJobsSuccess && dataJobs.length !== 0 && (
        <Card className="py-2 px-3">
          <div className="table-responsive mt-3">
            <table className="table table-sm table-centered mb-0 fs-13">
              <thead className="table-light">
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>View Details</th>
                </tr>
              </thead>

              <tbody>
                {dataJobs.map((job: any, index: number) => {
                  return (
                    <tr key={index} style={{ cursor: "pointer" }}>
                      <td>{job.name}</td>
                      <td>
                        <AddressItem address={job.address} />
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            onJobView(job);
                          }}
                          size="sm"
                          className="btn-cta"
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Card>
      )}
    </section>
  );
};

export default ViewDataLake;
