import StatisticsChartWidget2 from "../../../../components/StatisticsChartWidget2";

const ViewKeys = ({ createdKeys }: any) => {
  return (
    <div>
      <StatisticsChartWidget2
        title={createdKeys.publicKey}
        stats={createdKeys.dateCreated}
        containerClass="justify-content-between align-items-end pt-2"
        trend={createdKeys.isCustodian}
        colors={["#43d39e"]}
        data={[25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]}
      />
    </div>
  );
};

export default ViewKeys;
