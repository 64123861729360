export enum ProposalActionTypes {
  API_RESPONSE_SUCCESS = "@@proposals/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@proposals/API_RESPONSE_ERROR",

  GET_PROPOSALS_LIST = "@@proposals/GET_PROPOSALS_LIST",
  SAVE_REQUEST_FOR_PROPOSAL = "@@proposals/SAVE_REQUEST_FOR_PROPOSAL",

  DB_ENGINE_VALUES = "@@proposals/DB_ENGINE_VALUES",
  CPU_SPEC_VALUES = "@@proposals/CUPU_SPEC_VALUES",
  REGION_VALUES = "@@proposals/REGION_VALUES",

  GET_RESPONSE_FOR_PROPOSAL = "@@proposals/GET_RESPONSE_FOR_PROPOSAL",
  GET_ACTIVE_PROPOSALS = "@@proposals/GET_ACTIVE_PROPOSALS",

  SAVE_BID_FOR_PROPOSAL = "@@proposals/SAVE_BID_FOR_PROPOSAL",
  ACCEPT_PROPOSAL_BID = "@@proposals/ACCEPT_PROPOSAL_BID",

  FETCH_ACCEPTED_BIDS = "@@proposals/FETCH_ACCEPTED_BIDS",

  CREATE_DEPLOYMENT = "@@proposals/CREATE_DEPLOYMENT",
  FETCH_DEPLOYMENT_DETAILS = "@@proposals/FETCH_DEPLOYMENT_DETAILS",

  CREATE_NODE = "@@proposals/CREATE_NODE",
  GET_NODES_FOR_DEPLOYMENT = "@@proposals/GET_NODES_FOR_DEPLOYMENT",

  EDIT_NODE = "@@proposals/EDIT_NODE",
  DELETE_NODE = "@@proposals/DELETE_NODE",

  GET_MY_DEPLOYMENTS = "@@proposals/GET_MY_DEPLOYMENTS",

  RESET = "@@proposals/RESET",
}
