import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  getDbNodes as getDbNodesApi,
  getStakeTransactionDetails as getStakeTransactionDetailsApi,
  saveDbNode as saveDbNodeApi,
  saveStakeTransaction as saveStakeTransactionApi,
  getUserTermsAndConditions as getUserTermsAndConditionsApi,
} from "../../helpers/";

// actions
import { stakeApiResponseError, stakeApiResponseSuccess } from "./actions";

// constants
import { StakeActionTypes } from "./constants";

interface StakeData {
  payload: {
    regionName: string;
    name: string;
    txHash: number;
    nodeCount: number;
  };
  type: string;
}

/**
 * Save DB Node
 * @param {*} payload - regionName, name
 */
function* saveDbNode({
  payload: { regionName, name },
}: StakeData): SagaIterator {
  try {
    const response = yield call(saveDbNodeApi, {
      regionName,
      name,
    });

    yield put(
      stakeApiResponseSuccess(StakeActionTypes.SAVE_DB_NODES, response)
    );
  } catch (error: any) {
    yield put(stakeApiResponseError(StakeActionTypes.SAVE_DB_NODES, error));
  }
}

/**
 * List DB Nodes
 */
function* getDbNodes(): SagaIterator {
  try {
    const response = yield call(getDbNodesApi);
    yield put(
      stakeApiResponseSuccess(StakeActionTypes.GET_DB_NODES, response.data)
    );
  } catch (error: any) {
    yield put(stakeApiResponseError(StakeActionTypes.GET_DB_NODES, error));
  }
}

/**
 * Get Stake Transaction Details
 */
function* getStakeTransactionDetails(): SagaIterator {
  try {
    const response = yield call(getStakeTransactionDetailsApi);
    yield put(
      stakeApiResponseSuccess(
        StakeActionTypes.FETCH_STAKE_DETAILS,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      stakeApiResponseError(StakeActionTypes.FETCH_STAKE_DETAILS, error)
    );
  }
}

/**
 * Save Stake Transaction
 * @param {*} payload - nodeCount, txHash
 */
function* saveStakeTransaction({
  payload: { nodeCount, txHash },
}: StakeData): SagaIterator {
  try {
    const response = yield call(saveStakeTransactionApi, {
      nodeCount,
      txHash,
    });

    yield put(
      stakeApiResponseSuccess(StakeActionTypes.SAVE_STAKE_TRANSACTION, response)
    );
  } catch (error: any) {
    yield put(
      stakeApiResponseError(StakeActionTypes.SAVE_STAKE_TRANSACTION, error)
    );
  }
}

/**
 * Get User Terms and Conditions
 */
function* getUserTermsAndConditions(): SagaIterator {
  try {
    const response = yield call(getUserTermsAndConditionsApi);
    yield put(
      stakeApiResponseSuccess(
        StakeActionTypes.GET_USER_TERMS_AND_CONDITIONS,
        response.data
      )
    );
  } catch (error: any) {
    yield put(
      stakeApiResponseError(
        StakeActionTypes.GET_USER_TERMS_AND_CONDITIONS,
        error
      )
    );
  }
}

export function* watchGetDbNodes(): any {
  yield takeEvery(StakeActionTypes.GET_DB_NODES, getDbNodes);
}

export function* watchGetStakeTxDetails(): any {
  yield takeEvery(
    StakeActionTypes.FETCH_STAKE_DETAILS,
    getStakeTransactionDetails
  );
}

export function* watchSaveDbNode(): any {
  yield takeEvery(StakeActionTypes.SAVE_DB_NODES, saveDbNode);
}

export function* watchSaveStakeTxDetails(): any {
  yield takeEvery(
    StakeActionTypes.SAVE_STAKE_TRANSACTION,
    saveStakeTransaction
  );
}

export function* watchGetUserTerms(): any {
  yield takeEvery(
    StakeActionTypes.GET_USER_TERMS_AND_CONDITIONS,
    getUserTermsAndConditions
  );
}

function* stakeSaga() {
  yield all([
    fork(watchGetDbNodes),
    fork(watchGetStakeTxDetails),
    fork(watchSaveDbNode),
    fork(watchSaveStakeTxDetails),
    fork(watchGetUserTerms),
  ]);
}

export default stakeSaga;
