import { APICore } from "./apiCore";

const api = new APICore();

// get DB Nodes
function getDbNodes() {
  const baseUrl = "/dBNode/getNodes";
  return api.get(`${baseUrl}`, {});
}

//save DB Node
function saveDbNode(params: { regionName: string; name: string }) {
  const baseUrl = "/dBNode/save";

  const { regionName, name } = params;

  var form = new FormData();
  form.append("regionName", regionName);
  form.append("name", name);

  return api.create(`${baseUrl}`, form);
}

// get stake transaction details
function getStakeTransactionDetails() {
  const baseUrl = "/stakeTransaction/fetchStakeDetails";
  return api.get(`${baseUrl}`, {});
}

//save stake transaction
function saveStakeTransaction(params: { nodeCount: any; txHash: any }) {
  const baseUrl = "/stakeTransaction/save";

  const { nodeCount, txHash } = params;

  var form = new FormData();
  form.append("nodeCount", nodeCount);
  form.append("txHash", txHash);

  return api.create(`${baseUrl}`, form);
}

// get user terms and conditions
function getUserTermsAndConditions() {
  const baseUrl = "/termsAndConditions/fetchUserTermsAndConditions";
  return api.get(`${baseUrl}`, {});
}

export {
  getDbNodes,
  saveDbNode,
  getStakeTransactionDetails,
  saveStakeTransaction,
  getUserTermsAndConditions,
};
