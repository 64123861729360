import { Alert, Button, Card, Col, Form, Row, Tab } from "react-bootstrap";

import regionIcon from "../../../assets/images/icons/region.png";
import hardware from "../../../assets/images/icons/save.png";
import databaseIcon from "../../../assets/images/icons/database.png";
import network from "../../../assets/images/icons/network.png";
import backup from "../../../assets/images/icons/backup.png";
import frequency from "../../../assets/images/icons/frequency.png";
import nameIcon from "../../../assets/images/icons/name.png";
import cpuIcon from "../../../assets/images/icons/cpu.png";
import storageIcon from "../../../assets/images/icons/storage.png";
import memoryIcon from "../../../assets/images/icons/memory.png";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";

import { useSDK } from "@metamask/sdk-react";
import {
  getCpuSpecValues,
  getDbEngineValues,
  getRegionValues,
  saveRequestForProposal,
} from "../../../redux/actions";
import Loader from "../../../components/Loader";

import { Buffer } from "buffer";

const CreateNewProposal = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const { provider } = useSDK();

  const {
    loading,
    error,
    dbEngineValues,
    cpuSpecValues,
    regionValues,
    proposalSaved,
    saveLoading,
  } = useSelector((state: RootState) => ({
    loading: state.Proposal.loading,
    error: state.Proposal.error,
    dbEngineValues: state.Proposal.dbEngineValues,
    cpuSpecValues: state.Proposal.cpuSpecValues,
    regionValues: state.Proposal.regionValues,
    proposalSaved: state.Proposal.proposalSaved,
    saveLoading: state.Proposal.saveLoading,
  }));

  const [formNumber, setFormNumber] = useState(0);
  const [region, setRegion] = useState("");
  const [database, setDatabase] = useState("");
  const [cpuSpec, setCpuSpec] = useState("");
  const [ram, setRam] = useState("");
  const [storage, setStorage] = useState("");
  const [nodes, setNodes] = useState("");
  const [backups, setBackups] = useState("");
  const [backupFrequency, setBackupFrequency] = useState("");
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [name, setName] = useState("");
  const [showForm, setShowForm] = useState(true);

  const onNext = () => {
    let nextForm = formNumber + 1;

    setFormNumber(nextForm);
  };

  const onBack = () => {
    let prevForm = formNumber - 1;
    setFormNumber(prevForm);
  };

  const onSubmitClick = () => {
    let proposalData = {
      address: provider?.selectedAddress,
      dbEngineCode: database,
      memory: ram,
      storage: storage,
      redundancy: nodes,
      cpuSpecCode: cpuSpec,
      regionName: region,
      isBackupRequired: true,
      isSharedInstance: true,
      backupFrequency: backupFrequency,
      paymentFrequency: paymentFrequency || "MONTHLY",
      name: name,
    };

    getSignature(proposalData);
  };

  const getSignature = async (proposalData: any) => {
    let from = provider?.selectedAddress;

    const buffer = Buffer.from(JSON.stringify(proposalData), "utf8");

    let message = `0x${buffer.toString("hex")}`;

    var params = [from, message];

    try {
      await provider
        ?.request({
          method: "personal_sign",
          params: params,
        })
        .then((response) => {
          setShowForm(false);
          dispatch(
            saveRequestForProposal(JSON.stringify(proposalData), response)
          );
        });
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
  };

  const onRegionSelect = (event: any, field: any) => {
    if (field === "region") {
      setRegion(event.target.value);
    } else if (field === "database") {
      setDatabase(event.target.value);
    } else if (field === "ram") {
      setRam(event.target.value);
    } else if (field === "storage") {
      setStorage(event.target.value);
    } else if (field === "nodes") {
      setNodes(event.target.value);
    } else if (field === "backups") {
      setBackups(event.target.value);
    } else if (field === "backupFrequency") {
      setBackupFrequency(event.target.value);
    } else if (field === "paymentFrequency") {
      setPaymentFrequency(event.target.value);
    } else if (field === "cpuSpec") {
      setCpuSpec(event.target.value);
    }
  };

  const onNameUpdate = (event: any) => {
    setName(event.target.value);
  };

  const onRedirect = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    dispatch(getRegionValues());
    dispatch(getDbEngineValues());
    dispatch(getCpuSpecValues());
  }, [dispatch]);

  return (
    <Card className="mt-2">
      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}

      {(loading || saveLoading) && <Loader />}

      <Card.Body>
        <Tab.Container id="left-tabs-example" defaultActiveKey="total-views">
          {formNumber !== 4 && showForm && (
            <Card.Title className="text-center">
              {formNumber === 3
                ? "PROPOSAL FINALISATION"
                : "CREATE CONFIGURATION"}
            </Card.Title>
          )}

          <Row className="dashed-border-box p-2">
            <div>
              {showForm ? (
                formNumber === 0 ? (
                  <>
                    <Col xl={12} lg={12}>
                      <Form.Group as={Row} className="mb-3" name="region">
                        <Form.Label column lg={2}>
                          <img
                            className="form-label-icon"
                            src={regionIcon}
                            alt=""
                          />
                          <span className="form-label-text">Region</span>
                        </Form.Label>

                        <Col lg={4}>
                          <Form.Select
                            name="region"
                            onChange={(event) => {
                              onRegionSelect(event, "region");
                            }}
                          >
                            <option>Select Region</option>
                            {regionValues?.map((region: any) => (
                              <option value={region.name}>{region.name}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={2}>
                          <img
                            src={databaseIcon}
                            alt=""
                            className="form-label-icon"
                          />
                          <span className="form-label-text">Database</span>
                        </Form.Label>

                        <Col lg={4}>
                          <Form.Select
                            name="database"
                            onChange={(event) => {
                              onRegionSelect(event, "database");
                            }}
                          >
                            <option>Select Database</option>
                            {dbEngineValues?.map((dbEngine: any) => (
                              <option value={dbEngine.code}>
                                {dbEngine.code}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={12}>
                          <img
                            src={hardware}
                            alt=""
                            className="form-label-icon"
                          />
                          <span className="form-label-text">Hardware</span>
                        </Form.Label>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={2}>
                          <span className="form-label-text">RAM</span>
                        </Form.Label>

                        <Col lg={4}>
                          <Form.Select
                            name="ram"
                            onChange={(event) => {
                              onRegionSelect(event, "ram");
                            }}
                          >
                            <option>Select RAM</option>
                            <option value="6">6 GB</option>
                            <option value="12">12 GB</option>
                            <option value="16">16 GB</option>
                            <option value="32">32 GB</option>
                          </Form.Select>
                        </Col>

                        <Form.Label column lg={2}>
                          <span className="form-label-text">Storage</span>
                        </Form.Label>

                        <Col lg={4}>
                          <Form.Select
                            name="storage"
                            onChange={(event) => {
                              onRegionSelect(event, "storage");
                            }}
                          >
                            <option>Select Storage</option>
                            <option value="250">250 GB</option>
                            <option value="500">500 GB</option>
                            <option value="750">750 GB</option>
                            <option value="1000">1000 GB</option>
                          </Form.Select>
                        </Col>

                        <Form.Label column lg={2} className="mt-1">
                          <span className="form-label-text">CPU Spec</span>
                        </Form.Label>

                        <Col lg={4}>
                          <Form.Select
                            name="cpuSpec"
                            onChange={(event) => {
                              onRegionSelect(event, "cpuSpec");
                            }}
                          >
                            <option>Select CPU Spec</option>
                            {cpuSpecValues?.map((cpuSpec: any) => (
                              <option value={cpuSpec.code}>
                                {cpuSpec.code}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col lg={12} className="text-center form-button-column">
                      <Button type="button" variant="secondary">
                        Cancel
                      </Button>

                      <Button className="mr-1 btn-cta" onClick={onNext}>
                        Next
                      </Button>
                    </Col>
                  </>
                ) : formNumber === 1 ? (
                  <>
                    <Col xl={12} lg={12}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          <img
                            src={network}
                            alt=""
                            className="form-label-icon"
                          />
                          <span className="form-label-text">
                            No of nodes in cluster
                          </span>
                        </Form.Label>

                        <Col lg={4}>
                          <Form.Select
                            name="nodes"
                            onChange={(event) => {
                              onRegionSelect(event, "nodes");
                            }}
                          >
                            <option>Select Redundancies</option>
                            <option value="3">3</option>
                            <option value="5">5</option>
                            <option value="7">7</option>
                            <option value="9">9</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          <img
                            src={backup}
                            alt=""
                            className="form-label-icon"
                          />
                          <span className="form-label-text">Backups</span>
                        </Form.Label>

                        <Col lg={4}>
                          <Form.Select
                            name="backup"
                            onChange={(event) => {
                              onRegionSelect(event, "backup");
                            }}
                          >
                            <option>Select Backup</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="May Be">May Be</option>
                            <option value="Ask Me Later">Ask Me Later</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          <span className="form-label-text">
                            Backup Frequency
                          </span>
                        </Form.Label>

                        <Col lg={4}>
                          <Form.Select
                            name="backupFrequency"
                            onChange={(event) => {
                              onRegionSelect(event, "backupFrequency");
                            }}
                          >
                            <option>Select Frequency</option>
                            <option value="MONTHLY">Monthly</option>
                            <option value="QUARTERLY">Quarterly</option>
                            <option value="HALF YEARLY">Half Yearly</option>
                            <option value="YEARLY">Yearly</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={4}>
                          <img
                            src={frequency}
                            alt=""
                            className="form-label-icon"
                          />
                          <span className="form-label-text">
                            Payment Frequency
                          </span>
                        </Form.Label>

                        <Col lg={4}>
                          <Form.Select
                            name="paymentFrequency"
                            onChange={(event) => {
                              onRegionSelect(event, "paymentFrequency");
                            }}
                            key="paymentFrequency"
                          >
                            <option>Select Frequency</option>
                            <option value="MONTHLY">Monthly</option>
                            <option value="QUARTERLY">Quarterly</option>
                            <option value="HALF YEARLY">Half Yearly</option>
                            <option value="YEARLY">Yearly</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col lg={12} className="text-center form-button-column">
                      <Button
                        onClick={onBack}
                        type="button"
                        variant="secondary"
                      >
                        Back
                      </Button>

                      <Button
                        className="mr-1 btn-cta"
                        type="button"
                        onClick={onNext}
                      >
                        Next
                      </Button>
                    </Col>
                  </>
                ) : formNumber === 2 ? (
                  <>
                    <Col xl={12} lg={12}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column lg={12}>
                          <img
                            src={nameIcon}
                            alt=""
                            className="form-label-icon"
                          />
                          <span className="form-label-text">
                            Name of the Deployment
                          </span>
                        </Form.Label>

                        <Col lg={12}>
                          <Form.Control type="text" onChange={onNameUpdate} />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col lg={12} className="text-center form-button-column">
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={onBack}
                      >
                        Back
                      </Button>

                      <Button
                        type="button"
                        className="btn-cta"
                        onClick={onNext}
                      >
                        Submit
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col
                      xl={12}
                      lg={12}
                      className="proposal-review-box form-label-text"
                    >
                      <div className="proposal-review-row">
                        <div className="proposal-review-item proposal-review-deployment-name mt-1 mb-2">
                          <img
                            className="proposal-review-icon form-label-icon"
                            src={nameIcon}
                            alt=""
                          />
                          <div className="proposal-review-text">
                            <div className="proposal-review-label">
                              Deployment Name
                            </div>
                            <div className="proposal-review-value">
                              {name || "Test Deployment"}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="proposal-review-row">
                        <div className="proposal-review-group">
                          <div className="proposal-review-item mt-1 mb-2">
                            <img
                              className="proposal-review-icon form-label-icon"
                              src={regionIcon}
                              alt=""
                            />
                            <div className="proposal-review-text">
                              <div className="proposal-review-label">
                                Region
                              </div>
                              <div className="proposal-review-value">
                                {region}
                              </div>
                            </div>
                          </div>

                          <div className="proposal-review-item mt-1 mb-2">
                            <img
                              className="proposal-review-icon form-label-icon"
                              src={databaseIcon}
                              alt=""
                            />

                            <div className="proposal-review-text">
                              <div className="proposal-review-label">
                                Database
                              </div>

                              <div className="proposal-review-value">
                                {database || "MySQL"}
                              </div>
                            </div>
                          </div>

                          <div className="proposal-review-item mt-1 mb-2">
                            <img
                              className="proposal-review-icon form-label-icon"
                              src={network}
                              alt=""
                            />

                            <div className="proposal-review-text">
                              <div className="proposal-review-label">
                                Replication
                              </div>

                              <div className="proposal-review-value">
                                {nodes || "5"} nodes in the cluster
                              </div>
                            </div>
                          </div>

                          <div className="proposal-review-item mt-1 mb-2">
                            <img
                              className="proposal-review-icon form-label-icon"
                              src={backup}
                              alt=""
                            />

                            <div className="proposal-review-text">
                              <div className="proposal-review-label">
                                Backups
                              </div>

                              <div className="proposal-review-value">
                                Backup Frequency - {backupFrequency || "WEEKLY"}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="proposal-review-group">
                          <div className="proposal-review-item mt-1 mb-2">
                            <img
                              className="proposal-review-icon form-label-icon"
                              src={storageIcon}
                              alt=""
                            />

                            <div className="proposal-review-text">
                              <div className="proposal-review-label">
                                Storage
                              </div>

                              <div className="proposal-review-value">
                                {storage + " GB" || "500GB"}
                              </div>
                            </div>
                          </div>

                          <div className="proposal-review-item mt-1 mb-2">
                            <img
                              className="proposal-review-icon form-label-icon"
                              src={memoryIcon}
                              alt=""
                            />

                            <div className="proposal-review-text">
                              <div className="proposal-review-label">
                                Memory
                              </div>
                              <div className="proposal-review-value">
                                {ram + " GB" || "16GB"}
                              </div>
                            </div>
                          </div>

                          <div className="proposal-review-item mt-1 mb-2">
                            <img
                              className="proposal-review-icon form-label-icon"
                              src={cpuIcon}
                              alt=""
                            />
                            <div className="proposal-review-text">
                              <div className="proposal-review-label">
                                Processor
                              </div>
                              <div className="proposal-review-value">
                                {cpuSpec || "Intel Xeon"}
                              </div>
                            </div>
                          </div>
                          <div className="proposal-review-item mt-1 mb-2">
                            <img
                              className="proposal-review-icon form-label-icon"
                              src={frequency}
                              alt=""
                            />
                            <div className="proposal-review-text">
                              <div className="proposal-review-label">
                                Payment Frequency
                              </div>
                              <div className="proposal-review-value">
                                {paymentFrequency || "MONTHLY"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12} className="text-center form-button-column">
                      <Button onClick={onBack} variant="secondary">
                        Cancel
                      </Button>

                      <Button
                        className="mr-1 btn-cta"
                        type="button"
                        onClick={onSubmitClick}
                      >
                        Submit
                      </Button>
                    </Col>
                  </>
                )
              ) : null}

              {!saveLoading && proposalSaved && (
                <div className="text-center">
                  <h6 className="h5 fs-24 mb-3 text-center">
                    PROPOSAL FINALIZATION
                  </h6>

                  <p className="fs-20 mt-2 mb-3">
                    Thank you for the submission. Your request has been sent to
                    the network. We will send you a confirmation over Email once
                    resources have been allocated. You should get a response
                    within 2 hours
                  </p>

                  <Button
                    type="button"
                    className="btn-cta"
                    onClick={onRedirect}
                  >
                    Go To Dashboard
                  </Button>
                </div>
              )}
            </div>
          </Row>
        </Tab.Container>
      </Card.Body>
    </Card>
  );
};

export default CreateNewProposal;
