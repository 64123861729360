// constants
import { ProposalActionTypes } from "./constants";

export interface ProposalActionType {
  type:
    | ProposalActionTypes.API_RESPONSE_SUCCESS
    | ProposalActionTypes.API_RESPONSE_ERROR
    | ProposalActionTypes.GET_PROPOSALS_LIST
    | ProposalActionTypes.SAVE_REQUEST_FOR_PROPOSAL
    | ProposalActionTypes.DB_ENGINE_VALUES
    | ProposalActionTypes.CPU_SPEC_VALUES
    | ProposalActionTypes.REGION_VALUES
    | ProposalActionTypes.GET_RESPONSE_FOR_PROPOSAL
    | ProposalActionTypes.GET_ACTIVE_PROPOSALS
    | ProposalActionTypes.SAVE_BID_FOR_PROPOSAL
    | ProposalActionTypes.ACCEPT_PROPOSAL_BID
    | ProposalActionTypes.FETCH_ACCEPTED_BIDS
    | ProposalActionTypes.CREATE_DEPLOYMENT
    | ProposalActionTypes.FETCH_DEPLOYMENT_DETAILS
    | ProposalActionTypes.CREATE_NODE
    | ProposalActionTypes.GET_NODES_FOR_DEPLOYMENT
    | ProposalActionTypes.EDIT_NODE
    | ProposalActionTypes.DELETE_NODE
    | ProposalActionTypes.GET_MY_DEPLOYMENTS;
  payload: {} | string;
}

interface ProposalData {
  message: string;
  secret: string;
}

// common success
export const proposalApiResponseSuccess = (
  actionType: string,
  data: ProposalData | {}
): ProposalActionType => ({
  type: ProposalActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const proposalApiResponseError = (
  actionType: string,
  error: string
): ProposalActionType => ({
  type: ProposalActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//get list of saved proposals
export const getProposalsList = (): ProposalActionType => ({
  type: ProposalActionTypes.GET_PROPOSALS_LIST,
  payload: {},
});

//get list of active proposals
export const getActiveProposalsList = (): ProposalActionType => ({
  type: ProposalActionTypes.GET_ACTIVE_PROPOSALS,
  payload: {},
});

//save request for proposal
export const saveRequestForProposal = (
  message: string,
  secret: any
): ProposalActionType => ({
  type: ProposalActionTypes.SAVE_REQUEST_FOR_PROPOSAL,
  payload: { message, secret },
});

//get db engine values
export const getDbEngineValues = (): ProposalActionType => ({
  type: ProposalActionTypes.DB_ENGINE_VALUES,
  payload: {},
});

//get cpu spec values
export const getCpuSpecValues = (): ProposalActionType => ({
  type: ProposalActionTypes.CPU_SPEC_VALUES,
  payload: {},
});

//get region values
export const getRegionValues = (): ProposalActionType => ({
  type: ProposalActionTypes.REGION_VALUES,
  payload: {},
});

//get response for proposal
export const getResponseForProposal = (
  address: string
): ProposalActionType => ({
  type: ProposalActionTypes.GET_RESPONSE_FOR_PROPOSAL,
  payload: { address },
});

//save bid for proposal
export const saveBidForProposal = (
  secret: any,
  message: any
): ProposalActionType => ({
  type: ProposalActionTypes.SAVE_BID_FOR_PROPOSAL,
  payload: { secret, message },
});

//accept proposal bidexport
export const acceptBidForProposal = (
  secret: any,
  message: any
): ProposalActionType => ({
  type: ProposalActionTypes.ACCEPT_PROPOSAL_BID,
  payload: { secret, message },
});

//get response for proposal
export const fetchAcceptedBids = (): ProposalActionType => ({
  type: ProposalActionTypes.FETCH_ACCEPTED_BIDS,
  payload: {},
});

//create deployment
export const createDeployment = (
  rspraAddress: any,
  name: string
): ProposalActionType => ({
  type: ProposalActionTypes.CREATE_DEPLOYMENT,
  payload: { rspraAddress, name },
});

//fetch deployment details
export const fetchDeploymentDetails = (
  rspraAddress: any
): ProposalActionType => ({
  type: ProposalActionTypes.FETCH_DEPLOYMENT_DETAILS,
  payload: { rspraAddress },
});

//create nodecreateDeployment
export const createNode = (
  ipAddress: any,
  port: any,
  url: any,
  region: any,
  healthStatus: any,
  deploymentStatus: any,
  dbNodeName: any,
  description: any,
  deploymentAddress: any,
  response: any
): ProposalActionType => ({
  type: ProposalActionTypes.CREATE_NODE,
  payload: {
    ipAddress,
    port,
    url,
    region,
    healthStatus,
    deploymentStatus,
    dbNodeName,
    description,
    deploymentAddress,
    response,
  },
});

//fetch nodes for deployment
export const fetchNodesForDeployment = (
  deploymentAddress: any
): ProposalActionType => ({
  type: ProposalActionTypes.GET_NODES_FOR_DEPLOYMENT,
  payload: { deploymentAddress },
});

//edit node status
export const editNodeStatus = (
  healthStatus: any,
  deploymentStatus: any,
  address: any
): ProposalActionType => ({
  type: ProposalActionTypes.EDIT_NODE,
  payload: { healthStatus, deploymentStatus, address },
});

//delete node
export const deleteNode = (address: any): ProposalActionType => ({
  type: ProposalActionTypes.DELETE_NODE,
  payload: { address },
});

//fetch my deployments
export const fetchMyDeployments = (): ProposalActionType => ({
  type: ProposalActionTypes.GET_MY_DEPLOYMENTS,
  payload: {},
});
