// constants
import { StakeActionTypes } from "./constants";

export interface StakeActionType {
  type:
    | StakeActionTypes.API_RESPONSE_SUCCESS
    | StakeActionTypes.API_RESPONSE_ERROR
    | StakeActionTypes.GET_DB_NODES
    | StakeActionTypes.SAVE_DB_NODES
    | StakeActionTypes.FETCH_STAKE_DETAILS
    | StakeActionTypes.SAVE_STAKE_TRANSACTION
    | StakeActionTypes.GET_USER_TERMS_AND_CONDITIONS;
  payload: {} | string;
}

interface ProposalData {
  message: string;
  secret: string;
}

// common success
export const stakeApiResponseSuccess = (
  actionType: string,
  data: ProposalData | {}
): StakeActionType => ({
  type: StakeActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const stakeApiResponseError = (
  actionType: string,
  error: string
): StakeActionType => ({
  type: StakeActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//get list of db nodes
export const getDbNodes = (): StakeActionType => ({
  type: StakeActionTypes.GET_DB_NODES,
  payload: {},
});

//save db node
export const saveDbNode = (
  regionName: string,
  name: string
): StakeActionType => ({
  type: StakeActionTypes.SAVE_DB_NODES,
  payload: { regionName, name },
});

//get stake transaction details
export const getStakeTransactionDetails = (): StakeActionType => ({
  type: StakeActionTypes.FETCH_STAKE_DETAILS,
  payload: {},
});

//save stake transaction
export const saveStakeTransaction = (
  nodeCount: number,
  txHash: number
): StakeActionType => ({
  type: StakeActionTypes.SAVE_STAKE_TRANSACTION,
  payload: { nodeCount, txHash },
});

//get user terms and conditions
export const getUserTermsAndConditions = (): StakeActionType => ({
  type: StakeActionTypes.GET_USER_TERMS_AND_CONDITIONS,
  payload: {},
});
