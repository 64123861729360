import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getDbNodes } from "../../../redux/actions";

const Nodes = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { keys, loading, stakeDetails, dbNodes } = useSelector(
    (state: RootState) => ({
      keys: state.Auth.keys,
      loading: state.Stakes.loading,
      stakeDetails: state.Stakes.stakeDetails,
      dbNodes: state.Stakes.dbNodes,
    })
  );

  useEffect(() => {
    dispatch(getDbNodes());
  }, [dispatch]);

  return <section className="py-3 bg-body border-top"></section>;
};

export default Nodes;
