let termsData = [
  {
    heading: "Proof of stake",
    text: [
      "Kandola Network uses a variant of Proof-Of-Stake to reach consensus.",
      "For this, we need active participants—known as validators—to propose, verify, and vouch for the validity of blocks. In exchange, honest validators receive financial rewards.",
      "Importantly, as a validator you'll need to post TTKAN as collateral—in other words, have some funds at stake. The only way to become a validator is to make a one-way TTKAN transaction to the deposit contract on the Mainnet execution layer. The Beacon Chain (consensus layer) is used in parallel to keep track of all validator activity.",
    ],
  },
  {
    heading: "The deposit",
    text: [
      "To become a validator on the Beacon Chain, you need to deposit 32 TTKAN per validator that you wish to run.",
      "Like all Kandola Network transactions, deposits are non-reversible, but the ability to withdrawal your funds via a separate process after depositing remains under your control.",
    ],
  },
  {
    heading: "Using the terminal",
    text: [
      "To become a validator, you will need to be able to run commands in the terminal on your computer. Generating your new key pairs and installing the validator software are both done in the terminal.",
    ],
  },
  {
    heading: "Validator uptime",
    text: [
      "You'll only get your full rewards if your validator is online and up to date. This is your responsibility. If your validator goes offline you'll be penalized. The penalties for being offline are roughly equal to the rewards for actively participating.",
      "Like all Kandola Network transactions, deposits are non-reversible, but the ability to withdrawal your funds via a separate process after depositing remains under your control.",
    ],
  },
  {
    heading: "Bad validator behavior",
    text: [
      "If you try to cheat the system, or act contrary to the specification, you will be liable to incur a penalty known as slashing*. Running your validator keys simultaneously on two or more machines will result in slashing.",
      "*Simply being offline with an otherwise healthy network does not result in slashing, but will result in small inactivity penalties.",
    ],
  },
  {
    heading: "Key management",
    text: [
      "To become a validator you'll need to know about managing keys and protecting a mnemonic. If you are not yet familiar with keys and mnemonics, please do not proceed.",
      "We'll help you create a signing key for every validator you want to run. You may choose to provide a withdrawal address for your validator when generating your deposit data, which will permanently set the withdrawal address. This is recommended for most users.",
      "If you do not provide a withdrawal address with your initial deposit data, you will need to derive your withdrawal keys from your mnemonic at a later time, so store your mnemonic safely—it will be the ONLY way to withdraw your TTKAN when you chose to activate withdrawals.",
    ],
  },
  {
    heading: "Early adopter risks",
    text: [
      "You're joining a network in its early stages. As with any new piece of software, there is the potential for software bugs. While unlikely, potential bugs may result in slashing.",
    ],
  },
  {
    heading: "Staking checklist",
    text: [
      "Please review the staking checklist prior to proceeding. Use this as a guide to check off tasks as you complete validator setup.",
      "Also be sure you understand how staking withdrawals work before setting up your keys and deposit data.",
      "If you do not provide a withdrawal address with your initial deposit data, you will need to derive your withdrawal keys from your mnemonic at a later time, so store your mnemonic safely—it will be the ONLY way to withdraw your TTKAN when you chose to activate withdrawals.",
    ],
  },
  {
    heading: "Confirmation",
    text: ["I have read and agree to the Launchpad terms of service."],
  },
];

export const TermsData = (key: number) => {
  return termsData[key];
};

export const getTermsString = () => {
  let termsString = "";

  termsData.forEach((term) => {
    termsString = termsString.concat(term.heading);

    let termData = "";
    term.text.forEach((text) => {
      termData = termData + text;
    });

    termsString = termsString + termData;
  });

  return termsString;
};
