// constants
import { DataLakeActionTypes } from "./constants";

export interface DataLakeActionType {
  type:
    | DataLakeActionTypes.API_RESPONSE_SUCCESS
    | DataLakeActionTypes.API_RESPONSE_ERROR
    | DataLakeActionTypes.GET_DATALAKES
    | DataLakeActionTypes.CREATE_DATALAKE
    | DataLakeActionTypes.VIEW_DATALAKE_DETAILS
    | DataLakeActionTypes.GET_DATA_JOBS
    | DataLakeActionTypes.CREATE_DATA_JOB
    | DataLakeActionTypes.CREATE_JOB_CONDITION
    | DataLakeActionTypes.CREATE_JOB_EXECUTION
    | DataLakeActionTypes.CREATE_JOB_TRIGGER;
  payload: {} | string;
}

interface ProposalData {
  message: string;
  secret: string;
}

// common success
export const datalakeApiResponseSuccess = (
  actionType: string,
  data: ProposalData | {}
): DataLakeActionType => ({
  type: DataLakeActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const datalakeApiResponseError = (
  actionType: string,
  error: string
): DataLakeActionType => ({
  type: DataLakeActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//get list of data lakes
export const getDataLakesList = (): DataLakeActionType => ({
  type: DataLakeActionTypes.GET_DATALAKES,
  payload: {},
});

//create data lake
export const createDataLake = (
  name: string,
  blockchainList: any,
  fileList: any,
  deploymentList: any,
  s3url: any,
  url: any
): DataLakeActionType => ({
  type: DataLakeActionTypes.CREATE_DATALAKE,
  payload: { name, blockchainList, fileList, deploymentList, s3url, url },
});

//get data lake details
export const getDataLakeDetails = (): DataLakeActionType => ({
  type: DataLakeActionTypes.VIEW_DATALAKE_DETAILS,
  payload: {},
});

//create data job
export const createDataJob = (
  name: string,
  description: any,
  address: any
): DataLakeActionType => ({
  type: DataLakeActionTypes.CREATE_DATA_JOB,
  payload: { name, description, address },
});

//create data job trigger
export const createJobTrigger = (
  address: any,
  triggerType: any,
  cronExpression: any,
  onNewData: any,
  smartContractAddress: any,
  functionName: any,
  blockchainNetwork: any,
  smartContractParameters: any
): DataLakeActionType => ({
  type: DataLakeActionTypes.CREATE_JOB_TRIGGER,
  payload: {
    address,
    triggerType,
    cronExpression,
    onNewData,
    smartContractAddress,
    functionName,
    blockchainNetwork,
    smartContractParameters,
  },
});

//create data lake
export const createJobCondition = (
  address: string,
  sqlQuery: any,
  aiModel: any
): DataLakeActionType => ({
  type: DataLakeActionTypes.CREATE_JOB_CONDITION,
  payload: { address, sqlQuery, aiModel },
});

//create data lake
export const createJobExecution = (
  address: string,
  smartContractAddress: any,
  functionName: any,
  apiURL: any,
  paramList: any,
  outcomeType: any
): DataLakeActionType => ({
  type: DataLakeActionTypes.CREATE_JOB_EXECUTION,
  payload: {
    address,
    smartContractAddress,
    functionName,
    apiURL,
    paramList,
    outcomeType,
  },
});

//get data jobs details
export const getDataJobsDetails = (address: any): DataLakeActionType => ({
  type: DataLakeActionTypes.GET_DATA_JOBS,
  payload: { address },
});
