import { Modal, Row, Col, Button, Form } from "react-bootstrap";

import { FormInput } from "../../../../components";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";

// components
interface AddEditEventProps {
  isOpen?: boolean;
  onClose?: () => void;
  onCreate: (
    ipAddress: any,
    port: any,
    url: any,
    healthStatus: any,
    deploymentStatus: any,
    dbNodeName: any,
    description: any
  ) => void;
  selectedNode: any;
  onEdit: (healthStatus: any, deploymentStatus: any) => void;
}

const CreateNodeModal = ({
  isOpen,
  onClose,
  onCreate,
  selectedNode,
  onEdit,
}: AddEditEventProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const [ipAddress, setIpAddress] = useState(
    selectedNode?.address ? selectedNode?.ip : ""
  );
  const [dbNodeName, setNodeName] = useState(
    selectedNode?.address ? selectedNode?.name : ""
  );
  const [port, setPort] = useState(
    selectedNode?.address ? selectedNode?.port : ""
  );
  const [url, setUrl] = useState(
    selectedNode?.address ? selectedNode?.url : ""
  );
  const [healthStatus, setHealthStatus] = useState(
    selectedNode?.address ? selectedNode?.healthStatus.name : ""
  );
  const [deploymentStatus, setDeploymentStatus] = useState(
    selectedNode?.address ? selectedNode?.deploymentStatus.name : ""
  );
  const [description, setDescription] = useState(
    selectedNode?.address ? selectedNode?.description : ""
  );

  /*
   * handle form submission
   */
  const onSaveClick = () => {
    if (selectedNode?.address) {
      onEdit(healthStatus, deploymentStatus);
    } else {
      onCreate(
        ipAddress,
        port,
        url,
        healthStatus,
        deploymentStatus,
        dbNodeName,
        description
      );
    }
  };

  const onValueChange = (event: any, type: string) => {
    let value = event.target.value;

    if (type === "ipAddress") {
      setIpAddress(value);
    } else if (type === "port") {
      setPort(value);
    } else if (type === "url") {
      setUrl(value);
    } else if (type === "healthStatus") {
      setHealthStatus(value);
    } else if (type === "deploymentStatus") {
      setDeploymentStatus(value);
    } else if (type === "dbNodeName") {
      setNodeName(value);
    } else if (type === "description") {
      setDescription(value);
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
        <Modal.Title id="modal-title">
          <h5>{selectedNode?.address ? "Edit Node" : "Create Node"}</h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="px-4 pb-4 pt-0">
        {!selectedNode?.address && (
          <Row>
            <Col sm={6}>
              <FormInput
                type="text"
                label="DB Node Name"
                name="dbNodeName"
                className="form-control"
                placeholder="Enter DB Node Name"
                containerClass={"mb-3"}
                key="dbNodeName"
                onChange={(event) => {
                  onValueChange(event, "dbNodeName");
                }}
                value={dbNodeName}
              />
            </Col>

            <Col sm={6}>
              <FormInput
                type="text"
                label="Description"
                name="description"
                className="form-control"
                placeholder="Enter Description"
                containerClass={"mb-3"}
                key="description"
                onChange={(event) => {
                  onValueChange(event, "description");
                }}
                value={description}
              />
            </Col>

            <Col sm={6}>
              <FormInput
                type="text"
                label="IP Address"
                name="ipAddress"
                className="form-control"
                placeholder="Enter IP Address"
                containerClass={"mb-3"}
                key="ipAddress"
                onChange={(event) => {
                  onValueChange(event, "ipAddress");
                }}
                value={ipAddress}
              />
            </Col>

            <Col sm={6}>
              <FormInput
                type="text"
                label="Port"
                name="port"
                className="form-control"
                placeholder="Enter Port"
                containerClass={"mb-3"}
                key="port"
                onChange={(event) => {
                  onValueChange(event, "port");
                }}
                value={port}
              />
            </Col>

            <Col sm={6}>
              <FormInput
                type="text"
                label="URL"
                name="url"
                className="form-control"
                placeholder="Enter URL"
                containerClass={"mb-3"}
                key="url"
                onChange={(event) => {
                  onValueChange(event, "url");
                }}
                value={url}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col sm={6}>
            <Form.Label>Health Status</Form.Label>
            <Form.Select
              name="ram"
              onChange={(event) => {
                onValueChange(event, "healthStatus");
              }}
              value={healthStatus}
            >
              <option>Select Health Status</option>
              <option value="GOOD">GOOD</option>
              <option value="WARNING">WARNING</option>
              <option value="SEVERE">SEVERE</option>
              <option value="CRITICAL">CRITICAL</option>
            </Form.Select>
          </Col>

          <Col sm={6}>
            <Form.Label>Deployment Status</Form.Label>
            <Form.Select
              name="ram"
              onChange={(event) => {
                onValueChange(event, "deploymentStatus");
              }}
              value={deploymentStatus}
            >
              <option>Select Deployment Status</option>
              <option value="IN_PROGRESS">IN_PROGRESS</option>
              <option value="SUCCESS">SUCCESS</option>
              <option value="FAILED">FAILED</option>
            </Form.Select>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={8} className="text-end">
            <Button className="btn btn-light me-1" onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="success"
              type="submit"
              className="btn btn-success btn-cta"
              onClick={onSaveClick}
            >
              {selectedNode?.address ? "Save" : "Submit"}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CreateNodeModal;
