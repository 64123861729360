import { Alert, Button, Card, Col, Modal, Row } from "react-bootstrap";

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../../redux/store";

import Loader from "../../../../components/Loader";
import { useLocation } from "react-router-dom";
import {
  createNode,
  deleteNode,
  editNodeStatus,
  fetchDeploymentDetails,
  fetchNodesForDeployment,
} from "../../../../redux/actions";
import CreateNodeModal from "./CreateNodeModal";
import { useSDK } from "@metamask/sdk-react";
import { getDate } from "../../../../helpers/helperMethods";
import AddressItem from "../../../../components/AddressItem";

import { Buffer } from "buffer";

const ViewDeploymentDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { chainId, provider } = useSDK();

  let pathname = location.pathname.split("/");
  let deploymentId = pathname.pop();

  const [showNodeModal, setShowNodeModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState(Object);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const {
    error,
    detailsSuccess,
    detailsLoading,
    deploymentDetails,
    nodeDetails,
    nodeCreateLoading,
    nodesSuccess,
    nodesLoading,
    editNodeSuccess,
    editNodeLoading,
    deleteSuccess,
    deleteLoading,
    user,
  } = useSelector((state: RootState) => ({
    error: state.Proposal.error,
    deploymentDetails: state.Proposal.deploymentDetails,
    detailsSuccess: state.Proposal.detailsSuccess,
    detailsLoading: state.Proposal.detailsLoading,
    nodeDetails: state.Proposal.nodeDetails,
    nodeCreated: state.Proposal.nodeCreated,
    nodeCreateLoading: state.Proposal.nodeCreateLoading,
    nodesSuccess: state.Proposal.nodesSuccess,
    nodesLoading: state.Proposal.nodesLoading,
    editNodeSuccess: state.Proposal.editNodeSuccess,
    editNodeLoading: state.Proposal.editNodeLoading,
    deleteSuccess: state.Proposal.deleteSuccess,
    deleteLoading: state.Proposal.deleteLoading,
    user: state.Auth.user,
  }));

  const nodeProvider = user.authority[0] === "ROLE_DB_NODE_PROVIDER";

  const onAddClick = () => {
    setShowNodeModal(true);
  };

  const onCancel = () => {
    setShowNodeModal(false);
    setSelectedNode({});
  };

  const onCreate = (
    ipAddress: string,
    port: string,
    url: string,
    healthStatus: string,
    deploymentStatus: string,
    dbNodeName: string,
    description: string
  ) => {
    let deploymentAddress = deploymentDetails.address;
    let region = deploymentDetails.region;

    let nodeData = {
      ipAddress,
      port,
      url,
      region,
      healthStatus,
      deploymentStatus,
      dbNodeName,
      description,
      deploymentAddress,
    };

    // const msgParams = JSON.stringify({
    //   domain: {
    //     // Defining the chain aka Rinkeby testnet or Ethereum Main Net
    //     chainId: chainId,
    //     // Give a user-friendly name to the specific contract you are signing for.
    //     name: "Add Node",
    //     // If name isn't enough add verifying contract to make sure you are establishing contracts with the proper entity
    //     verifyingContract: "0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC",
    //     // Just lets you know the latest version. Definitely make sure the field name is correct.
    //     version: "1",
    //   },

    //   message: nodeData,
    //   // Refers to the keys of the *types* object below.
    //   primaryType: "Proposal",
    //   types: {
    //     EIP712Domain: [
    //       { name: "name", type: "string" },
    //       { name: "version", type: "string" },
    //       { name: "chainId", type: "uint256" },
    //       { name: "verifyingContract", type: "address" },
    //     ],
    //     // Refer to PrimaryType
    //     Proposal: [
    //       { name: "deploymentAddress", type: "string" },
    //       { name: "ipAddress", type: "string" },
    //       { name: "port", type: "string" },
    //       { name: "url", type: "string" },
    //       { name: "region", type: "string" },
    //       { name: "healthStatus", type: "string" },
    //       { name: "deploymentStatus", type: "string" },
    //       { name: "dbNodeName", type: "string" },
    //       { name: "description", type: "string" },
    //     ],
    //   },
    // });

    getSignature(nodeData);
  };

  const getSignature = async (nodeData: any) => {
    let from = provider?.selectedAddress;
    const buffer = Buffer.from(JSON.stringify(nodeData), "utf8");

    let message = `0x${buffer.toString("hex")}`;
    var params = [from, message];

    try {
      await provider
        ?.request({
          method: "personal_sign",
          params: params,
        })
        .then((response) => {
          setShowNodeModal(false);

          dispatch(
            createNode(
              nodeData.ipAddress,
              nodeData.port,
              nodeData.url,
              nodeData.region,
              nodeData.healthStatus,
              nodeData.deploymentStatus,
              nodeData.dbNodeName,
              nodeData.description,
              nodeData.deploymentAddress,
              response
            )
          );
        });
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
  };

  const getNodes = () => {
    dispatch(fetchNodesForDeployment(deploymentDetails.address));
  };

  const onEditNode = (node: any) => {
    setSelectedNode(node);
    setShowNodeModal(true);
  };

  const onDeleteNode = (node: any) => {
    setSelectedNode(node);
    setConfirmDelete(true);
  };

  const onEditSave = (healthStatus: any, deploymentStatus: any) => {
    setShowNodeModal(false);
    dispatch(
      editNodeStatus(healthStatus, deploymentStatus, selectedNode.address)
    );

    setSelectedNode({});
  };

  const onDeleteConfirm = () => {
    setConfirmDelete(false);
    dispatch(deleteNode(selectedNode.address));

    setSelectedNode({});
  };

  const onClose = () => {
    setConfirmDelete(false);
  };

  useEffect(() => {
    dispatch(fetchDeploymentDetails(deploymentId));
  }, [dispatch, deploymentId]);

  return (
    <>
      {(detailsLoading ||
        nodeCreateLoading ||
        nodesLoading ||
        editNodeLoading ||
        deleteLoading) && <Loader />}

      {editNodeSuccess && (
        <Alert variant="success" className="my-2">
          Node Status Edited Successfully!
        </Alert>
      )}

      {deleteSuccess && (
        <Alert variant="success" className="my-2">
          Node Deleted Successfully!
        </Alert>
      )}

      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}

      {confirmDelete && (
        <Modal
          show={confirmDelete}
          onHide={onClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
            <Modal.Title>Delete Node</Modal.Title>
          </Modal.Header>

          <Modal.Body className="px-4 pb-4 pt-0">
            <p>Are you sure you want to delete this node?</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="primary"
              className="btn-cta"
              onClick={onDeleteConfirm}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Card className="mt-2">
        <Card.Body>
          <h4 className="card-title header-title mb-3">Deployment Details</h4>

          {deploymentDetails !== "" && detailsSuccess && (
            <>
              <div>
                <h5>Deployment Name : {deploymentDetails.name}</h5>
                <h5>
                  Deployment Address :{" "}
                  <AddressItem address={deploymentDetails.address} />{" "}
                </h5>
                <h5>
                  Request for Storage Address :{" "}
                  <AddressItem address={deploymentDetails.rspAddress} />
                </h5>
                <h5>
                  DB Owner Address :{" "}
                  <AddressItem address={deploymentDetails.dbOwner} />{" "}
                </h5>

                <Row>
                  <Col md={4}>
                    <h5>Database Name : {deploymentDetails.dbName}</h5>
                  </Col>

                  <Col md={4}>
                    <h5>Price : {deploymentDetails.price}</h5>
                  </Col>

                  <Col md={4}>
                    <h5>Memory : {deploymentDetails.memory} GB</h5>
                  </Col>

                  <Col md={4}>
                    <h5>CPU : {deploymentDetails.cpu}</h5>
                  </Col>

                  <Col md={4}>
                    <h5>Region : {deploymentDetails.region}</h5>
                  </Col>

                  <Col md={4}>
                    <h5>Redundancy : {deploymentDetails.memory}</h5>
                  </Col>
                </Row>
              </div>

              {!nodesSuccess && !nodesLoading && getNodes()}
            </>
          )}

          {nodeProvider && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "1rem",
              }}
            >
              <Button onClick={onAddClick} className="btn-cta">
                Add Node
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>

      <Row>
        {nodeDetails &&
          nodeDetails.length !== 0 &&
          nodeDetails.map((node: any) => (
            <Col md={4}>
              <Card>
                <Card.Body>
                  <h5>Node Name : {node.name}</h5>
                  <h5>Date Created : {getDate(node.dateCreated)}</h5>
                  <h5>IP Address : {node.ip}</h5>
                  <h5>Port : {node.port}</h5>
                  <h5>URL : {node.url}</h5>
                  <h5>Health Status : {node.healthStatus.name}</h5>
                  <h5>Deployment Status : {node.deploymentStatus.name}</h5>

                  {nodeProvider && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "1rem",
                      }}
                    >
                      <Button
                        onClick={() => {
                          onEditNode(node);
                        }}
                      >
                        Edit
                      </Button>

                      <Button
                        onClick={() => {
                          onDeleteNode(node);
                        }}
                        variant="danger"
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>

      {showNodeModal && (
        <CreateNodeModal
          isOpen={showNodeModal}
          onClose={onCancel}
          onCreate={onCreate}
          selectedNode={selectedNode}
          onEdit={onEditSave}
        />
      )}
    </>
  );
};

export default ViewDeploymentDetails;
