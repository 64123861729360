// apicore
import { APICore } from "../../helpers/api/apiCore";

// constants
import { StakeActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  user: api.getLoggedInUser(),
  loading: false,
  transactions: [],
  dbNodes: [],
  userTerms: [],
};

interface StakeActionType {
  type:
    | StakeActionTypes.API_RESPONSE_SUCCESS
    | StakeActionTypes.API_RESPONSE_ERROR
    | StakeActionTypes.GET_DB_NODES
    | StakeActionTypes.SAVE_DB_NODES
    | StakeActionTypes.FETCH_STAKE_DETAILS
    | StakeActionTypes.SAVE_STAKE_TRANSACTION
    | StakeActionTypes.GET_USER_TERMS_AND_CONDITIONS;
  payload: {
    actionType?: string;
    data?: {};
    error?: string;
  };
}

interface State {
  loading?: boolean;
  value?: boolean;
  userTerms?: {};
}

const Stake = (state: State = INIT_STATE, action: StakeActionType): any => {
  switch (action.type) {
    case StakeActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case StakeActionTypes.GET_DB_NODES: {
          return {
            ...state,
            dbNodes: action.payload.data,
            loading: false,
          };
        }
        case StakeActionTypes.FETCH_STAKE_DETAILS: {
          return {
            ...state,
            stakeDetails: action.payload.data,
            loading: false,
          };
        }
        case StakeActionTypes.SAVE_DB_NODES: {
          return {
            ...state,
            dbNodeSaved: true,
            dbNodeSaveLoading: false,
          };
        }
        case StakeActionTypes.SAVE_STAKE_TRANSACTION: {
          return {
            ...state,
            saveStakeLoading: false,
            stakeTxSaved: true,
          };
        }
        case StakeActionTypes.GET_USER_TERMS_AND_CONDITIONS: {
          return {
            ...state,
            userTerms: action.payload.data,
            loading: false,
          };
        }
        default:
          return { ...state };
      }

    case StakeActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case StakeActionTypes.GET_DB_NODES: {
          return {
            ...state,
            error: action.payload.data,
            loading: false,
          };
        }
        case StakeActionTypes.FETCH_STAKE_DETAILS: {
          return {
            ...state,
            error: action.payload.data,
            loading: false,
          };
        }
        case StakeActionTypes.SAVE_DB_NODES: {
          return {
            ...state,
            dbNodeSaved: false,
            dbNodeSaveLoading: false,
            error: action.payload.data,
          };
        }
        case StakeActionTypes.SAVE_STAKE_TRANSACTION: {
          return {
            ...state,
            saveStakeLoading: false,
            stakeTxSaved: false,
            error: action.payload.data,
          };
        }
        case StakeActionTypes.GET_USER_TERMS_AND_CONDITIONS: {
          return {
            ...state,
            userTerms: "",
            loading: false,
            error: action.payload.data,
          };
        }
        default:
          return { ...state };
      }

    case StakeActionTypes.FETCH_STAKE_DETAILS:
      return { ...state, loading: true, stakeDetails: "", stakeTxSaved: "" };

    case StakeActionTypes.GET_DB_NODES:
      return { ...state, loading: true, dbNodes: [] };

    case StakeActionTypes.SAVE_DB_NODES:
      return {
        ...state,
        dbNodeSaved: false,
        dbNodeSaveLoading: true,
      };

    case StakeActionTypes.SAVE_STAKE_TRANSACTION:
      return {
        ...state,
        saveStakeLoading: true,
        stakeTxSaved: false,
      };

    case StakeActionTypes.GET_USER_TERMS_AND_CONDITIONS: {
      return {
        ...state,
        userTerms: "",
        loading: true,
      };
    }

    default:
      return { ...state };
  }
};

export default Stake;
