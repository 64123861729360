import { Alert, Button, Card } from "react-bootstrap";

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../../redux/store";
import { createDeployment, fetchAcceptedBids } from "../../../../redux/actions";

import Loader from "../../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { getDate } from "../../../../helpers/helperMethods";
import CreateDeploymentModal from "./CreateDeploymentModal";

const Bids = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedBid, setSelectedBid] = useState(Object);

  const {
    bidsLoading,
    error,
    bidsSuccess,
    acceptedBids,
    deployment,
    createSuccess,
    createLoading,
  } = useSelector((state: RootState) => ({
    bidsLoading: state.Proposal.bidsLoading,
    error: state.Proposal.error,
    bidsSuccess: state.Proposal.bidsSuccess,
    acceptedBids: state.Proposal.acceptedBids,
    deployment: state.Proposal.deployment,
    createSuccess: state.Proposal.createSuccess,
    createLoading: state.Proposal.createLoading,
  }));

  const onCreateDeployment = (bid: any) => {
    setSelectedBid(bid);
    setShowCreateModal(true);
  };

  const onCloseModal = () => {
    setShowCreateModal(false);

    setSelectedBid(Object);
  };

  const onCreateSubmit = (name: string) => {
    setShowCreateModal(false);

    dispatch(createDeployment(selectedBid?.address, name));
  };

  const onViewDeployment = (bid: any) => {
    navigate("/deployment-details/" + bid.address);
  };

  const onCreateSuccess = () => {
    navigate("/deployment-details/" + selectedBid.address);
  };

  useEffect(() => {
    dispatch(fetchAcceptedBids());
  }, [dispatch]);

  return (
    <>
      {(bidsLoading || createLoading) && <Loader />}

      {createSuccess && onCreateSuccess()}

      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}

      <Card className="mt-2">
        <Card.Body>
          <h4 className="card-title header-title">Bids Won</h4>

          {bidsSuccess && acceptedBids?.message.length !== 0 && (
            <div className="table-responsive mt-3">
              <table className="table table-sm table-centered mb-0 fs-13">
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>CPU Spec</th>
                    <th>DB Engine</th>
                    <th>Memory</th>
                    <th>Storage</th>
                    <th>Payment Frequency</th>
                    <th>Redundancy</th>
                    <th>Region</th>
                    <th>Date Accepted</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {acceptedBids.message.map((bid: any, index: number) => {
                    return (
                      <tr key={index} style={{ cursor: "pointer" }}>
                        <td>{bid.name}</td>
                        <td>{bid.price}</td>
                        <td>{bid.cpuSpec}</td>
                        <td>{bid.dbEngine}</td>
                        <td>{bid.memory} GB</td>
                        <td>{bid.storage} GB</td>
                        <td>{bid.paymentFrequency}</td>
                        <td>{bid.redundancy}</td>
                        <td>{bid.region}</td>
                        <td>{getDate(bid.dateAccepted)}</td>
                        <td>
                          <Button
                            onClick={() => {
                              if (bid.deployment?.address) {
                                onViewDeployment(bid);
                              } else {
                                onCreateDeployment(bid);
                              }
                            }}
                            size="sm"
                            className="btn-cta"
                          >
                            {bid.deployment?.address
                              ? "View Deployment"
                              : "Create Deployment"}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </Card.Body>
      </Card>

      {showCreateModal && (
        <CreateDeploymentModal
          isOpen={showCreateModal}
          onClose={onCloseModal}
          selectedBid={selectedBid?.name}
          onSubmit={onCreateSubmit}
        />
      )}
    </>
  );
};

export default Bids;
