// apicore
import { APICore } from "../../helpers/api/apiCore";

// constants
import { AuthActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  user: api.getLoggedInUser(),
  loading: false,
  keys: [],
};

interface UserData {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
}

interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.VALIDATE_CODE
    | AuthActionTypes.LIST_KEYS
    | AuthActionTypes.SAVE_NON_CUSTODIAN_KEY
    | AuthActionTypes.SAVE_TERMS_AND_CONDITIONS
    | AuthActionTypes.SIGNUP_USER;
  payload: {
    actionType?: string;
    data?: UserData | {};
    error?: string;
  };
}

interface State {
  user?: UserData | {};
  loading?: boolean;
  value?: boolean;
}

const Auth = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case AuthActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            user: action.payload.data,
            loading: false,
            userSignUp: true,
          };
        }
        case AuthActionTypes.LOGOUT_USER: {
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true,
            userLoggedIn: false,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          return {
            ...state,
            resetPasswordSuccess: action.payload.data,
            loading: false,
            passwordReset: true,
          };
        }
        case AuthActionTypes.VALIDATE_CODE: {
          return {
            ...state,
            validateCodeSuccess: action.payload.data,
            loading: false,
            codeValidated: true,
          };
        }
        case AuthActionTypes.LIST_KEYS: {
          return {
            ...state,
            keys: action.payload.data,
            loading: false,
          };
        }
        case AuthActionTypes.SAVE_NON_CUSTODIAN_KEY: {
          return {
            ...state,
            keySaved: true,
            keyData: action.payload.data,
          };
        }
        case AuthActionTypes.SAVE_TERMS_AND_CONDITIONS: {
          return {
            ...state,
            termsSaved: true,
            termsSaveLoading: false,
          };
        }
        default:
          return { ...state };
      }

    case AuthActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            passwordReset: false,
          };
        }
        case AuthActionTypes.VALIDATE_CODE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            codeValidated: false,
          };
        }
        case AuthActionTypes.LIST_KEYS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        case AuthActionTypes.SAVE_NON_CUSTODIAN_KEY: {
          return {
            ...state,
            keySaved: false,
            error: action.payload.error,
          };
        }
        default:
          return { ...state };
      }

    case AuthActionTypes.LIST_KEYS:
      return { ...state, loading: true, keys: [] };

    case AuthActionTypes.LOGIN_USER:
      return { ...state, loading: true, userLoggedIn: false };
    case AuthActionTypes.LOGOUT_USER:
      return { ...state, loading: true, userLogout: false };
    case AuthActionTypes.SAVE_TERMS_AND_CONDITIONS: {
      return {
        ...state,
        termsSaved: false,
        termsSaveLoading: true,
      };
    }
    case AuthActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false,
        resetPasswordSuccess: null,
      };
    case AuthActionTypes.SIGNUP_USER: {
      return {
        ...state,
        userSignUp: false,
        loading: true,
        codeValidated: false,
        validateCodeSuccess: "",
      };
    }
    default:
      return { ...state };
  }
};

export default Auth;
