import { APICore } from "./apiCore";

const api = new APICore();

function saveRequestForProposal(params: { message: string; secret: any }) {
  const baseUrl = "/reqForStorageProposal/save";

  const { message, secret } = params;

  var form = new FormData();
  form.append("signature", secret);
  form.append("message", message);

  return api.create(`${baseUrl}`, form);
}

function getProposalsList() {
  const baseUrl = "/reqForStorageProposal/getProposals";

  return api.get(`${baseUrl}`, {});
}

function getActiveProposalsList() {
  const baseUrl = "/reqForStorageProposal/fetchAllActiveProposals";

  return api.get(`${baseUrl}`, {});
}

function getDbEngineValues() {
  const baseUrl = "/dbEngine/index";

  return api.get(`${baseUrl}`, {});
}

function getCpuSpecValues() {
  const baseUrl = "/cpuSpec/index";

  return api.get(`${baseUrl}`, {});
}

function getRegionValues() {
  const baseUrl = "/region/index";

  return api.get(`${baseUrl}`, {});
}

function getResponseForProposal(params: { address: string }) {
  const baseUrl =
    "/rSPResponse/getResponseForProposal?rspAddress=" + params.address;

  return api.get(`${baseUrl}`, {});
}

function saveBidForProposal(params: { secret: any; message: any }) {
  const baseUrl = "/rSPResponse/save";

  const { secret, message } = params;

  var form = new FormData();
  form.append("signature", secret);
  form.append("message", message);

  return api.create(`${baseUrl}`, form);
}

function acceptBidForProposal(params: { secret: any; message: any }) {
  const baseUrl = "/rSPResponseAcceptance/save";

  const { secret, message } = params;

  var form = new FormData();
  form.append("signature", secret);
  form.append("message", message);

  return api.create(`${baseUrl}`, form);
}

function fetchAcceptedBids() {
  const baseUrl = "/rSPResponseAcceptance/fetchAcceptances";

  return api.get(`${baseUrl}`, {});
}

function createDeployment(params: { rspraAddress: any; name: string }) {
  const baseUrl = "/dBNode/createDeployment";

  const { rspraAddress, name } = params;

  var form = new FormData();
  form.append("rspraAddress", rspraAddress);
  form.append("name", name);

  return api.create(`${baseUrl}`, form);
}

function getDeploymentDetails(params: { rspraAddress: any }) {
  const baseUrl =
    "/dBNode/fetchDeploymentForProposal?rspraAddress=" + params.rspraAddress;

  return api.get(`${baseUrl}`, {});
}

function createNode(params: {
  ipAddress: any;
  port: any;
  url: any;
  region: any;
  healthStatus: any;
  deploymentStatus: any;
  dbNodeName: any;
  description: any;
  deploymentAddress: any;
  response: any;
}) {
  const baseUrl = "dBNode/addNodeToDeployment";

  const {
    ipAddress,
    port,
    url,
    region,
    healthStatus,
    deploymentStatus,
    dbNodeName,
    description,
    deploymentAddress,
    response,
  } = params;

  var form = new FormData();
  form.append("ip", ipAddress);
  form.append("port", port);
  form.append("url", url);
  form.append("regionName", region);
  form.append("healthStatus", healthStatus);
  form.append("deploymentStatus", deploymentStatus);
  form.append("dbNodeName", dbNodeName);
  form.append("description", description);
  form.append("deploymentAddress", deploymentAddress);
  form.append("signature", response);

  return api.create(`${baseUrl}`, form);
}

function getNodesForDeployment(params: { deploymentAddress: any }) {
  const baseUrl =
    "/dBNode/fetchNodesForDeployment?deploymentAddress=" +
    params.deploymentAddress;

  return api.get(`${baseUrl}`, {});
}

function editNodeStatus(params: {
  healthStatus: any;
  deploymentStatus: any;
  address: any;
}) {
  const baseUrl = "dBNode/updateNodeStatus";

  const { healthStatus, deploymentStatus, address } = params;

  var form = new FormData();
  form.append("healthStatus", healthStatus);
  form.append("deploymentStatus", deploymentStatus);
  form.append("address", address);

  return api.create(`${baseUrl}`, form);
}

function deleteNode(params: { address: any }) {
  const baseUrl = "/dBNode/deleteNode";

  var form = new FormData();
  form.append("address", params.address);

  return api.create(`${baseUrl}`, form);
}

function getMyDeployments() {
  const baseUrl = "dBNode/fetchMyDeployments";

  return api.get(`${baseUrl}`, {});
}

export {
  getProposalsList,
  saveRequestForProposal,
  getDbEngineValues,
  getCpuSpecValues,
  getRegionValues,
  getResponseForProposal,
  getActiveProposalsList,
  saveBidForProposal,
  acceptBidForProposal,
  fetchAcceptedBids,
  createDeployment,
  getDeploymentDetails,
  createNode,
  getNodesForDeployment,
  editNodeStatus,
  deleteNode,
  getMyDeployments,
};
