export enum DataLakeActionTypes {
  API_RESPONSE_SUCCESS = "@@datalake/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@datalake/API_RESPONSE_ERROR",

  GET_DATALAKES = "@@datalake/GET_DATALAKES",
  CREATE_DATALAKE = "@@datalake/CREATE_DATALAKE",
  VIEW_DATALAKE_DETAILS = "@@datalake/VIEW_DATALAKE_DETAILS",

  CREATE_DATA_JOB = "@@datalake/CREATE_DATA_JOB",
  CREATE_JOB_TRIGGER = "@@datalake/CREATE_JOB_TRIGGER",
  CREATE_JOB_CONDITION = "@@datalake/CREATE_JOB_CONDITION",
  CREATE_JOB_EXECUTION = "@@datalake/CREATE_JOB_EXECUTION",
  GET_DATA_JOBS = "@@datalake/GET_DATA_JOBS",

  RESET = "@@datalake/RESET",
}
