import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";

import { FormInput } from "../../components";
import { useEffect, useState } from "react";
import { TermsData, getTermsString } from "./TermsData";
import { getHash } from "../../helpers/helperMethods";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";

import { useSDK } from "@metamask/sdk-react";
import {
  getUserTermsAndConditions,
  saveTermsAndConditions,
} from "../../redux/actions";
import Loader from "../../components/Loader";

import { Buffer } from "buffer";
import { Navigate } from "react-router-dom";

const Terms = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { provider } = useSDK();

  const [currentItem, setCurrentItem] = useState(0);
  const [acceptedTerms, setAcceptedTerms] = useState<any[]>([]);

  const { termsSaved, termsSaveLoading, userTerms } = useSelector(
    (state: RootState) => ({
      termsSaved: state.Auth.termsSaved,
      termsSaveLoading: state.Auth.termsSaveLoading,
      userTerms: state.Stakes.userTerms,
    })
  );

  const onSubmit = () => {
    let termsString = "";
    termsString = getTermsString();

    let termsHash = getHash(termsString);

    let msgData = { termsAndConditions: termsHash };

    getSignature(msgData);
  };

  const getSignature = async (messageData: any) => {
    let from = provider?.selectedAddress;
    const buffer = Buffer.from(JSON.stringify(messageData), "utf8");

    let message = `0x${buffer.toString("hex")}`;

    var params = [from, message];

    try {
      await provider
        ?.request({
          method: "personal_sign",
          params: params,
        })
        .then((response) => {
          dispatch(
            saveTermsAndConditions(JSON.stringify(messageData), response)
          );
        });
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
  };

  const onSelectItem = (key: number) => {
    setCurrentItem(key);
  };

  const getItemContent = (text: string) => {
    let data = TermsData(currentItem);

    return (
      <div>
        <h4>{data.heading}</h4>

        {data.text.map((para) => (
          <p>{para}</p>
        ))}
      </div>
    );
  };

  const onTermAccept = () => {
    let newAcceptedItems: any[] = [];
    newAcceptedItems = acceptedTerms;

    let index = newAcceptedItems.findIndex((item) => currentItem === item);

    if (index === -1) {
      newAcceptedItems.push(currentItem);
      setAcceptedTerms(newAcceptedItems);
    }

    onSelectItem(currentItem + 1);
  };

  useEffect(() => {
    dispatch(getUserTermsAndConditions());
  }, [dispatch]);

  return (
    <section className="py-3 bg-body border-top">
      {termsSaved && <Navigate to={"/staking-profile"}></Navigate>}

      {userTerms.status === "success" && (
        <Navigate to={"/staking-profile"}></Navigate>
      )}

      {termsSaveLoading && <Loader />}

      <h3 className="text-center mb-4">Terms and Conditions</h3>

      <Container>
        <Row>
          <Col md={3}>
            <ListGroup>
              <ListGroup.Item
                className={
                  currentItem === 0
                    ? "mb-2 terms-list-item terms-list-selected"
                    : "mb-2 terms-list-item"
                }
                onClick={() => {
                  onSelectItem(0);
                }}
              >
                1. Proof of Stake
              </ListGroup.Item>

              <ListGroup.Item
                className={
                  currentItem === 1
                    ? "mb-2 terms-list-item terms-list-selected"
                    : "mb-2 terms-list-item"
                }
                onClick={() => {
                  onSelectItem(1);
                }}
              >
                2. Deposit
              </ListGroup.Item>

              <ListGroup.Item
                className={
                  currentItem === 2
                    ? "mb-2 terms-list-item terms-list-selected"
                    : "mb-2 terms-list-item"
                }
                onClick={() => {
                  onSelectItem(2);
                }}
              >
                3. The terminal
              </ListGroup.Item>

              <ListGroup.Item
                className={
                  currentItem === 3
                    ? "mb-2 terms-list-item terms-list-selected"
                    : "mb-2 terms-list-item"
                }
                onClick={() => {
                  onSelectItem(3);
                }}
              >
                4. Uptime
              </ListGroup.Item>

              <ListGroup.Item
                className={
                  currentItem === 4
                    ? "mb-2 terms-list-item terms-list-selected"
                    : "mb-2 terms-list-item"
                }
                onClick={() => {
                  onSelectItem(4);
                }}
              >
                5. Bad Behavior
              </ListGroup.Item>

              <ListGroup.Item
                className={
                  currentItem === 5
                    ? "mb-2 terms-list-item terms-list-selected"
                    : "mb-2 terms-list-item"
                }
                onClick={() => {
                  onSelectItem(5);
                }}
              >
                6. Key Management
              </ListGroup.Item>

              <ListGroup.Item
                className={
                  currentItem === 6
                    ? "mb-2 terms-list-item terms-list-selected"
                    : "mb-2 terms-list-item"
                }
                onClick={() => {
                  onSelectItem(6);
                }}
              >
                7. Early Adoption Risks
              </ListGroup.Item>

              <ListGroup.Item
                className={
                  currentItem === 7
                    ? "mb-2 terms-list-item terms-list-selected"
                    : "mb-2 terms-list-item"
                }
                onClick={() => {
                  onSelectItem(7);
                }}
              >
                8. Checklist
              </ListGroup.Item>

              <ListGroup.Item
                className={
                  currentItem === 8
                    ? "mb-2 terms-list-item terms-list-selected"
                    : "mb-2 terms-list-item"
                }
                onClick={() => {
                  onSelectItem(8);
                }}
              >
                9. Confirmation
              </ListGroup.Item>
            </ListGroup>
          </Col>

          <Col md={9}>
            {getItemContent("text")}

            {currentItem !== 8 && (
              <Button
                onClick={() => {
                  onTermAccept();
                }}
                className="btn-cta"
              >
                I Accept
              </Button>
            )}
          </Col>
        </Row>

        {currentItem === 8 && (
          <>
            <FormInput
              label=" I understand and agree to the terms-of-service and everything stated in the previous sections."
              type="checkbox"
              name="checkboxsignup"
              containerClass={"mt-3 mb-3"}
              defaultChecked
            />

            <Button
              onClick={onSubmit}
              disabled={acceptedTerms.length !== 8 || termsSaveLoading}
              className="btn-cta"
            >
              Submit
            </Button>
          </>
        )}
      </Container>
    </section>
  );
};

export default Terms;
