import { Modal, Row, Col, Button } from "react-bootstrap";

import { FormInput } from "../../../../components";
import { useState } from "react";

// components
interface AddEditEventProps {
  isOpen?: boolean;
  onClose?: () => void;
  selectedBid?: string;
  onSubmit: (value: any) => void;
}

const CreateDeploymentModal = ({
  isOpen,
  onClose,
  selectedBid,
  onSubmit,
}: AddEditEventProps) => {
  const [deploymentName, setDeploymentName] = useState("");

  /*
   * handle form submission
   */
  const onSaveClick = () => {
    onSubmit(deploymentName);
  };

  const onValueChange = (event: any) => {
    setDeploymentName(event.target.value);
  };

  return (
    <Modal show={isOpen} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
        <Modal.Title id="modal-title">
          <h5>Create Deployment for {selectedBid}</h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="px-4 pb-4 pt-0">
        <Row>
          <Col sm={12}>
            <FormInput
              type="text"
              label="Deployment Name"
              name="deploymentName"
              className="form-control"
              placeholder="Enter Deployment Name"
              containerClass={"mb-3"}
              key="deploymentName"
              onChange={(event) => {
                onValueChange(event);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={8} className="text-end">
            <Button className="btn btn-light me-1" onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="success"
              type="submit"
              className="btn btn-success btn-cta"
              onClick={onSaveClick}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CreateDeploymentModal;
