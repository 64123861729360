// apicore
import { APICore } from "../../helpers/api/apiCore";

// constants
import { DataLakeActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  user: api.getLoggedInUser(),
  loading: false,
  transactions: [],
  dbNodes: [],
  userTerms: [],
};

interface DatalakeActionType {
  type:
    | DataLakeActionTypes.API_RESPONSE_SUCCESS
    | DataLakeActionTypes.API_RESPONSE_ERROR
    | DataLakeActionTypes.GET_DATALAKES
    | DataLakeActionTypes.CREATE_DATALAKE
    | DataLakeActionTypes.VIEW_DATALAKE_DETAILS
    | DataLakeActionTypes.CREATE_DATA_JOB
    | DataLakeActionTypes.CREATE_JOB_CONDITION
    | DataLakeActionTypes.CREATE_JOB_EXECUTION
    | DataLakeActionTypes.CREATE_JOB_TRIGGER
    | DataLakeActionTypes.GET_DATA_JOBS;
  payload: {
    actionType?: string;
    data?: {};
    error?: string;
  };
}

interface State {
  loading?: boolean;
  value?: boolean;
  dataLakes?: {};
}

const Datalake = (
  state: State = INIT_STATE,
  action: DatalakeActionType
): any => {
  switch (action.type) {
    case DataLakeActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case DataLakeActionTypes.GET_DATALAKES: {
          return {
            ...state,
            dataLakes: action.payload.data,
            getDataLakesSuccess: true,
            loading: false,
          };
        }
        case DataLakeActionTypes.CREATE_DATALAKE: {
          return {
            ...state,
            createDataLakeSuccess: true,
            createLoading: false,
          };
        }
        case DataLakeActionTypes.VIEW_DATALAKE_DETAILS: {
          return {
            ...state,
            dataLakeDetails: action.payload.data,
            detailsSuccess: true,
            detailsLoading: false,
          };
        }
        case DataLakeActionTypes.GET_DATA_JOBS: {
          return {
            ...state,
            dataJobs: action.payload.data,
            getDataJobsSuccess: true,
            dataJobsLoading: false,
          };
        }

        case DataLakeActionTypes.CREATE_DATA_JOB: {
          return {
            ...state,
            createJobSuccess: true,
            createJobLoading: false,
            jobAddress: action.payload.data,
          };
        }
        case DataLakeActionTypes.CREATE_JOB_TRIGGER: {
          return {
            ...state,
            createTriggerSuccess: true,
            createTriggerLoading: false,
          };
        }
        case DataLakeActionTypes.CREATE_JOB_CONDITION: {
          return {
            ...state,
            createConditionSuccess: true,
            createConditionLoading: false,
          };
        }
        case DataLakeActionTypes.CREATE_JOB_EXECUTION: {
          return {
            ...state,
            createExecutionSuccess: true,
            createExecutionLoading: false,
          };
        }
        default:
          return { ...state };
      }

    case DataLakeActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case DataLakeActionTypes.GET_DATALAKES: {
          return {
            ...state,
            error: action.payload.data,
            getDataLakesSuccess: false,
            loading: false,
          };
        }
        case DataLakeActionTypes.CREATE_DATALAKE: {
          return {
            ...state,
            error: action.payload.data,
            createDataLakeSuccess: false,
            createLoading: false,
          };
        }
        case DataLakeActionTypes.VIEW_DATALAKE_DETAILS: {
          return {
            ...state,
            detailsSuccess: false,
            detailsLoading: false,
            error: action.payload.data,
          };
        }
        case DataLakeActionTypes.GET_DATA_JOBS: {
          return {
            ...state,
            error: action.payload.data,
            getDataJobsSuccess: false,
            dataJobsLoading: false,
          };
        }

        case DataLakeActionTypes.CREATE_DATA_JOB: {
          return {
            ...state,
            createJobSuccess: false,
            createJobLoading: false,
            error: action.payload.data,
          };
        }
        case DataLakeActionTypes.CREATE_JOB_TRIGGER: {
          return {
            ...state,
            createTriggerSuccess: false,
            createTriggerLoading: false,
            error: action.payload.data,
          };
        }
        case DataLakeActionTypes.CREATE_JOB_CONDITION: {
          return {
            ...state,
            createConditionSuccess: false,
            createConditionLoading: false,
            error: action.payload.data,
          };
        }
        case DataLakeActionTypes.CREATE_JOB_EXECUTION: {
          return {
            ...state,
            createExecutionSuccess: false,
            createExecutionLoading: false,
            error: action.payload.data,
          };
        }
        default:
          return { ...state };
      }

    case DataLakeActionTypes.GET_DATALAKES:
      return {
        ...state,
        dataLakes: [],
        getDataLakesSuccess: false,
        loading: true,
        createDataLakeSuccess: false,
      };

    case DataLakeActionTypes.CREATE_DATALAKE:
      return {
        ...state,
        createDataLakeSuccess: false,
        createLoading: true,
      };

    case DataLakeActionTypes.VIEW_DATALAKE_DETAILS: {
      return {
        ...state,
        dataLakeDetails: [],
        detailsSuccess: false,
        detailsLoading: true,
      };
    }

    case DataLakeActionTypes.GET_DATA_JOBS: {
      return {
        ...state,
        dataJobs: [],
        getDataJobsSuccess: false,
        dataJobsLoading: true,
        createJobSuccess: false,
        createTriggerSuccess: false,
        createConditionSuccess: false,
        createExecutionSuccess: false,
      };
    }

    case DataLakeActionTypes.CREATE_DATA_JOB: {
      return {
        ...state,
        createJobSuccess: false,
        createJobLoading: true,
        getDataJobsSuccess: false,
      };
    }
    case DataLakeActionTypes.CREATE_JOB_TRIGGER: {
      return {
        ...state,
        createTriggerSuccess: false,
        createTriggerLoading: true,
      };
    }
    case DataLakeActionTypes.CREATE_JOB_CONDITION: {
      return {
        ...state,
        createConditionSuccess: false,
        createConditionLoading: true,
      };
    }
    case DataLakeActionTypes.CREATE_JOB_EXECUTION: {
      return {
        ...state,
        createExecutionSuccess: false,
        createExecutionLoading: true,
      };
    }

    default:
      return { ...state };
  }
};

export default Datalake;
