import { Button, Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AddressItem from "../../../components/AddressItem";

const ViewDataJob = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <section className="py-3 bg-body border-top">
      <Button onClick={onBack} className="btn-cta">
        Back To Data Lake
      </Button>

      <Card className="mt-2">
        <Card.Body style={{ display: "flex" }}>
          {/* <div>
            <h4>Name : {state.name}</h4>
            <h4>
              Address : <AddressItem address={state.address || ""} />
            </h4>
          </div> */}

          <div className="proposal-review-item mt-1 mb-2">
            <div className="proposal-review-text">
              <div className="proposal-review-label data-job-label">Name</div>
              <div className="proposal-review-value">{state.name}</div>
            </div>
          </div>

          <div className="proposal-review-item mt-1 mb-2">
            <div className="proposal-review-text">
              <div className="proposal-review-label data-job-label">
                Address
              </div>

              <div className="proposal-review-value">
                <AddressItem address={state.address || ""} />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="proposal-review-item mt-1 mb-2">
            <div className="proposal-review-text">
              <div className="proposal-review-label data-job-label">
                Trigger
              </div>
              <div className="proposal-review-value">
                {state.trigger.triggerType}
              </div>
            </div>
          </div>

          {state.trigger.triggerType === "CRON" && (
            <div className="proposal-review-item mt-1 mb-2">
              <div className="proposal-review-text">
                <div className="proposal-review-label data-job-label">
                  CRON Expression
                </div>

                <div className="proposal-review-value">
                  {state.trigger.cronExpression}
                </div>
              </div>
            </div>
          )}

          {state.trigger.internalApiURL && (
            <div className="proposal-review-item mt-1 mb-2">
              <div className="proposal-review-text">
                <div className="proposal-review-label data-job-label">
                  Internal API URL
                </div>

                <div className="proposal-review-value">
                  {state.trigger.internalApiURL}
                </div>
              </div>
            </div>
          )}

          {state.trigger.triggerType === "SMART_CONTRACT_EVENT" && (
            <>
              <div className="proposal-review-item mt-1 mb-2">
                <div className="proposal-review-text">
                  <div className="proposal-review-label data-job-label">
                    Blockchain Network
                  </div>

                  <div className="proposal-review-value">
                    {state.trigger.blockchainNetwork}
                  </div>
                </div>
              </div>

              <div className="proposal-review-item mt-1 mb-2">
                <div className="proposal-review-text">
                  <div className="proposal-review-label data-job-label">
                    Smart Contract Address
                  </div>

                  <div className="proposal-review-value">
                    <AddressItem address={state.trigger.smartContractAddress} />
                  </div>
                </div>
              </div>

              <div className="proposal-review-item mt-1 mb-2">
                <div className="proposal-review-text">
                  <div className="proposal-review-label data-job-label">
                    Event Name
                  </div>

                  <div className="proposal-review-value">
                    {state.trigger.functionName}
                  </div>
                </div>
              </div>

              {state.trigger.paramsList && (
                <div className="proposal-review-item mt-1 mb-2">
                  <div className="proposal-review-text">
                    <div className="proposal-review-label data-job-label">
                      Params List
                    </div>

                    <div className="proposal-review-value">
                      {state.trigger.paramsList.map((param: any) => (
                        <p>{param}</p>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Card.Body>

        {/* <Card.Body>
          <h4>Trigger : {state.trigger.triggerType}</h4>

          {state.trigger.triggerType === "CRON" && (
            <h4>CRON Expression : {state.trigger.cronExpression}</h4>
          )}

          {state.trigger.internalApiURL && (
            <h4>Internal API URL : {state.trigger.internalApiURL}</h4>
          )}

          {state.trigger.triggerType === "SMART_CONTRACT_EVENT" && (
            <>
              <h4>Blockchain Network : {state.trigger.blockchainNetwork}</h4>
              <h4>
                Smart Contract Address :{" "}
                <AddressItem address={state.trigger.smartContractAddress} />{" "}
              </h4>

              <h4>Event Name : {state.trigger.functionName}</h4>

              <h4>
                Params List :{" "}
                {state.trigger.paramsList.map((param: any) => (
                  <p>{param}</p>
                ))}
              </h4>
            </>
          )}

          {state.trigger.triggerType === "ON_NEW_DATA" && <h4>-</h4>}
        </Card.Body> */}
      </Card>

      <Card>
        <Card.Body>
          <div className="proposal-review-item mt-1 mb-2">
            <div className="proposal-review-text">
              <div className="proposal-review-label data-job-label">
                Condition
              </div>

              <div className="proposal-review-value">
                {state.condition.aiModel ? "AI Model" : "SQL Query"} :{" "}
                {state.condition.aiModel || state.condition.sqlQuery}
              </div>
            </div>
          </div>

          {/* <h4>
            Condition
            <h4>
              {state.condition.aiModel ? "AI Model" : "SQL Query"} :{" "}
              {state.condition.aiModel || state.condition.sqlQuery}
            </h4>
          </h4> */}
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="proposal-review-item mt-1 mb-2">
            <div className="proposal-review-text">
              <div className="proposal-review-label data-job-label">
                Execution
              </div>

              {state.outcome.outcomeType === "EXTERNAL_API" && (
                <div className="proposal-review-value">
                  API URL : {state.outcome.apiURL}
                </div>
              )}

              {state.outcome.outcomeType === "SMART_CONTRACT" && (
                <>
                  <div className="proposal-review-label data-job-label">
                    Smart Contract Address
                  </div>

                  <div className="proposal-review-value">
                    <AddressItem address={state.outcome.smartContractAddress} />
                  </div>

                  <div className="proposal-review-label data-job-label">
                    Event Name
                  </div>

                  <div className="proposal-review-value">
                    {state.outcome.functionName}
                  </div>

                  <div className="proposal-review-label data-job-label">
                    Params List
                  </div>

                  <div className="proposal-review-value">
                    {state.outcome.paramsList.map((param: any) => (
                      <p>{param}</p>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Card.Body>
        {/* <Card.Body>
          <h4>Execution</h4>

          {state.outcome.outcomeType === "EXTERNAL_API" && (
            <h4>API URL : {state.outcome.apiURL}</h4>
          )}

          {state.outcome.outcomeType === "SMART_CONTRACT" && (
            <>
              <h4>
                Smart Contract Address :{" "}
                <AddressItem address={state.outcome.smartContractAddress} />{" "}
              </h4>

              <h4>Event Name : {state.outcome.functionName}</h4>

              <h4>
                Params List :{" "}
                {state.outcome.paramsList.map((param: any) => (
                  <p>{param}</p>
                ))}
              </h4>
            </>
          )}
        </Card.Body> */}
      </Card>
    </section>
  );
};

export default ViewDataJob;
